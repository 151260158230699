import React, { useContext } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import AppContext from './app-context'
import isEmpty from 'lodash.isempty'

function GenePoolsPage (props) {
  const { genePools = [] } = props
  const { geneticTestConfig, isPreview } = useContext(AppContext)

  if (isEmpty(geneticTestConfig)) return null

  const {
    title,
    description1,
    description2,
    bgImg
  } = (geneticTestConfig || { gpp: { } }).gpp

  return (
    <div className='gene-pool-page page-wrap'>
      <Navbar isApp={!isPreview} />
      <div className='page-header-wrap genepool-head' style={{ backgroundImage: `url(${bgImg})` }}>
        <div className='content'>
          <div className='page-title'>
            <h1>{title}</h1>
          </div>
        </div>
      </div>
      <div className='content-row gene-info'>
        <div className='content-block'>
          <div className='description t-justify'>
            {description1}
          </div>
          <div className='description t-justify'>
            {description2}
          </div>
        </div>
      </div>
      {genePools.map((gp) =>
        <div key={'gp-page-' + gp.idx} className='content-row'>
          <div className='content-block'>
            {gp.icon && <div className='icon'><img src={gp.icon} alt='gp-icon' /></div>}
            <div className='t-b title' id={'gp-' + gp.idx}>{gp.name}</div>
            <div className='line mb15' style={{ borderColor: gp.color }} />
            {gp.region && <div className='t-c t-b mb15 t-black'>{gp.region}</div>}
            <div className='description t-justify' dangerouslySetInnerHTML={{ __html: gp.story }} />
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default GenePoolsPage
