import React, { useState, useEffect } from 'react'
import { FaArrowCircleUp } from 'react-icons/fa'
import '../styles/scroll-arrow.scss'

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false)

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }
    }
    window.addEventListener('scroll', checkScrollTop)
    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [])

  return (
    <FaArrowCircleUp className='scrollTop' onClick={scrollTop} style={{ height: 40, display: showScroll ? 'flex' : 'none' }} />
  )
}

export default ScrollArrow
