import React, { useContext } from 'react'
import Navbar from '../components/navbar'
import About from '../components/homepage/about'
import Package from '../components/homepage/package'
import Footer from '../components/footer'
import AppContext from './app-context'
import ReferencedTests from './homepage/referenced-tests'
import isEmpty from 'lodash.isempty'

function HomePage (props) {
  const { geneticTestConfig, isPreview } = useContext(AppContext)

  if (isEmpty(geneticTestConfig)) return null

  const {
    title,
    subtitle,
    bgImg
  } = (geneticTestConfig || { hp: {} }).hp

  return (
    <div className='homepage-wrap' id='homepage'>
      <div className='homepage-banner' style={{ backgroundImage: `url(${bgImg})` }}>
        <Navbar isApp={!isPreview} />
        <div className='welcome-block'>
          <div className='welcome-text'>
            <div className='title'>{title}</div>
            <div className='sub-title'>{subtitle}</div>
            <div className='test-btn'>
              <a href='#test'>Choose your test</a>
            </div>
          </div>
        </div>
      </div>
      <ReferencedTests />
      <About />
      <Package {...props} />
      <Footer />
    </div>
  )
}

export default HomePage
