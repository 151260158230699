import React, { useState, useCallback } from 'react'
import '../styles/_preview-page.scss'
// import _ from 'lodash'

import AncestryMap from './preview/ancestry-map'
import DetailsWindow from './preview/details-window'
import CulturesWindow from './preview/cultures-window'
import MyResultsWindow from './preview/my-result-window'
import Sidebar from './preview/sidebar'
import TimelineSlider from './preview/timeline-slider'
import Navbar from './navbar'

import { useWindowSize } from '../utils/hooks'

export const PreviewContext = React.createContext()

const mobileTreshold = 600

function Preview (props) {
  const { culturesConf, genePoolsConf, report, isPreview, isBeginner } = props
  const [sidebarClickedCulture, _setSidebarClickedCulture] = useState()
  const [doScrollIntoView, setDoScrollIntoView] = useState(false)
  const [filterdCultures, setFilterdCultures] = useState(null)
  const [auto, _setAuto] = useState(true)
  const [newCulture, setNewCulture] = useState(undefined)
  const [showDetails, setShowDetails] = useState(false)
  const [showMyResults, _setShowMyResults] = useState(false)
  const [showCultures, _setShowCultures] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(0)
  const [isEndTime, setIsEndTime] = useState(false)
  const [localTime, setLocalTime] = useState(null)

  const setAuto = useCallback((auto) => {
    if (auto) _setSidebarClickedCulture(null)
    _setAuto(auto)
  }, [_setSidebarClickedCulture, _setAuto])

  const setSidebarClickedCulture = useCallback((culture, showDetails, scrollIntoView = true) => {
    if (culture && auto) setAuto(false)
    if (showMyResults && showDetails) _setShowMyResults(false)
    _setSidebarClickedCulture(culture)
    setDoScrollIntoView(scrollIntoView)
    setShowDetails(showDetails)
  }, [auto, showMyResults, _setShowMyResults, _setSidebarClickedCulture, setShowDetails, setAuto])

  const setShowMyResults = useCallback((show) => {
    _setShowMyResults(show)
    if (auto) setAuto(false)
    if (show && showDetails) setShowDetails(false)
    if (show && showCultures) _setShowCultures(false)
  }, [setShowDetails, _setShowCultures, setAuto, auto, showDetails, showCultures])

  const setShowCultures = useCallback((show) => {
    _setShowCultures(show)
    if (show && showDetails) setShowDetails(false)
    if (show && showMyResults) setShowMyResults(false)
  }, [setShowDetails, _setShowCultures, setShowMyResults, showDetails, showMyResults])

  const setLocalTimeIfLocalTimeIsSmaller = useCallback((minTime) => {
    if (localTime < minTime) setLocalTime(minTime)
  }, [localTime, setLocalTime])

  const { width } = useWindowSize()

  const isMobile = width < mobileTreshold

  const previewContextValue = {
    genePoolsConf,
    cultures: filterdCultures,
    sidebarClickedCulture,
    setSidebarClickedCulture,
    auto,
    newCulture,
    setNewCulture,
    setAuto,
    navbarHeight,
    setNavbarHeight,
    isMobile,
    setShowCultures,
    setShowMyResults,
    setLocalTime,
    setLocalTimeIfLocalTimeIsSmaller,
    report,
    doScrollIntoView,
    isBeginner
  }

  const culturesDefined = culturesConf?.length > 0

  const style = {}
  if (!isMobile) style.height = `calc(100vh - ${navbarHeight}px)`

  const desktopContainer = culturesDefined
    ? (
      <>
        <CulturesWindow show={showCultures} culturesConf={culturesConf} isMobile />
        <DetailsWindow show={showDetails} culturesConf={culturesConf} />
        <MyResultsWindow show={showMyResults} />
        <ErrorBoundary>
          <TimelineSlider culturesConf={culturesConf} setFilterdCultures={setFilterdCultures} localTime={localTime} setIsEndTime={setIsEndTime} />
        </ErrorBoundary>
      </>
      )
    : (<></>)

  const mobileContainer = culturesDefined
    ? (
      <>
        <CulturesWindow show={showCultures} culturesConf={culturesConf} isMobile />
        <DetailsWindow show={showDetails} culturesConf={culturesConf} />
        <MyResultsWindow show={showMyResults} />
        <TimelineSlider culturesConf={culturesConf} setFilterdCultures={setFilterdCultures} localTime={localTime} setIsEndTime={setIsEndTime} />
        <Sidebar mobile culturesConf={culturesConf} isEndTime={isEndTime} />
      </>
      )
    : (<></>)

  // console.log('window.location', window.location, culturesConf, genePoolsConf, report, isBeginner, isPreview)

  return (
    <PreviewContext.Provider value={previewContextValue}>
      {!isMobile && <Navbar isApp={!isPreview} />}
      <div className='l-preview' style={style}>
        {!isMobile && <Sidebar culturesConf={culturesConf} isEndTime={isEndTime} />}
        <div className='map' style={style}>
          <AncestryMap />
          {!isMobile && desktopContainer}
        </div>
        {isMobile && mobileContainer}
      </div>
    </PreviewContext.Provider>
  )
}

export default Preview

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}
