import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/lapita_text_01.jpg'
import image02 from '../../assets/images/cultures/lapita_text_02.jpg'
import image03 from '../../assets/images/cultures/lapita_text_03.jpg'

export const lapitaPreview = 'The Lapita people were the first to discover and settle the western and south tropical pacific islands between 1600 and 500 BC. Since no other culture existed there and no languages were spoken, the Lapita exhibit a high degree of genetic similarity and history even on remote islands. Their name derives from xapeta’a, a word in the Haveke language of New Caledonia, meaning “to dig a hole” or “the place where one digs” misunderstood by the archaeologists working on the site. They were expert seafarers and navigators, populating islands across a band in the South Pacific from Papua New Guinea to the Marquesas Islands, including Fiji, Samoa, and Tonga leap-frogging across between islands across distances of over 800 kilometers of open ocean in their outrigger sailing canoes. Their descendants went on to populate New Zealand, Hawaii, and Easter Island. In popular culture, the story of the Lapita has inspired the Disney story of Moana.'

function lapita (props) {
  return (
    <>
      <h2>Lapita Culture of Tonga (<Years culture={props} />)</h2>
      <br />
      {lapitaPreview}
      <br />
      <br />
      <img src={image01} alt='Lapita Culture of Tonga' />
      <legend> Lapita pottery from Vanuatu (Source: Wikipedia)</legend>
      <br />
      It remains a mystery of how and why the Lapita spread through the islands of the South Pacific. There was no overcrowding or shortage of food on the islands, and when the voyagers were to embark on a sailing expedition, the next island over the open ocean would be unseen and unknown. There was no guarantee their canoe would find an inhabitable island or their way back to shore before the sailors ran out of food or water. One hypothesis is that land would be passed down in a family only to the oldest son, thus encouraging the younger sons to explore and migrate.
      <br />
      <br />
      The Lapita often lived in stilt houses by lagoons or on offshore islands. They cleared the forests near their settlements for agriculture, raising domestic animals—pigs, dogs, and chickens—and taro, yams, coconuts, bananas, and breadfruit. They were also known for their distinctive, tooth-stamped pottery, which became less elaborate over time and as the people spread east, and their use of obsidian, which they used for tool making and trade.
      <br />
      <br />
      The Lapita people reached Tonga around 900 BCE and created small communities near lagoons or reefs. Here, the Lapita’s diet consisted mostly of what they caught from the sea. Within 200 years of arriving on Tonga, their pottery became simpler with fewer forms and coarser stamping until decorations of pottery ceased altogether.
      <br />
      <br />
      <img src={image02} alt='Lapita Culture of Tonga' />
      <legend> Nuku island, Kapa, Vava’u (Source: Wikimedia Commons)</legend>
      <br />
      The skeletons of two Lapita people—one male and one female—were found in Tonga and are the oldest human remains found in Polynesia. Analysis of the DNA of the female suggests she is a descendant of people of East Asia. Though the female’s haplogroups could not be determined, the male’s paternal haplogroup was O, and his maternal haplogroup was B. Both haplogroups are very ancient. They have first appeared in East or Southeast Asia some 40,000-50,000 years ago and remain common among these populations.
      <br />
      <br />
      <img src={image03} alt='Lapita Culture of Tonga' />
      <legend> Reconstruction of the face of a Lapita woman (Source: Wikipedia)</legend>
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/3kaI5ED'>"Lapita Peoples (The Peoples of South-East Asia and the Pacific)"</a></strong>
      <br />
      <br />
      <iframe title='Lapita Peoples (The Peoples of South-East Asia and the Pacific)' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=1557861129&asins=1557861129&linkId=61fa0e33261251f14111393bf981ab3f&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default lapita
