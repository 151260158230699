import Papa from 'papaparse'
export class Enum {
  constructor (...keys) {
    keys.forEach((key, i) => (this[key] = key))
    Object.freeze(this)
  }

  * [Symbol.iterator] () {
    for (const key of Object.keys(this)) yield key
  }
}

export function separateAndOrderBeginnerAndAdvancedCulturesAndGenePools (culturesConf, genePoolsConf) {
  const advancedCultures = [...culturesConf]
  const advancedGenepools = genePoolsConf.filter(gp => gp.advanced === 1)
  const beginnerGenePools = genePoolsConf.filter(gp => gp.beginner === 1)
  const beginnerCultures = culturesConf.filter(c => c.test_type === 1)

  advancedGenepools.sort((a, b) => a.order - b.order)
  beginnerGenePools.sort((a, b) => a.order - b.order)

  const colors = [...new Set(advancedGenepools.map(gp => gp.color?.toLowerCase()))]

  beginnerCultures.sort((a, b) => colors.indexOf(a.color?.toLowerCase()) - colors.indexOf(b.color?.toLowerCase()))
  advancedCultures.sort((a, b) => colors.indexOf(a.color?.toLowerCase()) - colors.indexOf(b.color?.toLowerCase()))

  return {
    beginnerCultures,
    beginnerGenePools,
    advancedCultures,
    advancedGenepools
  }
}

/**
 * Loops over an object and all properties including dots(".") will be transformed to objects.
 * This is recursive, meaning new created objects containing properties with dots, will be converted too.
 * @param {Object} obj
 * @returns Object - an object with nested objects in case some properties contained dots.
 */
export function deepen (obj) {
  const result = {}
  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.')
    // Create sub-objects along path as needed
    let target = result
    while (parts.length > 1) {
      const part = parts.shift()
      target = target[part] = target[part] || {}
    }
    // Set value at end of path
    target[parts[0]] = obj[objectPath]
  }

  return result
}

export async function getDataFromTxt (file) {
  const response = await window.fetch(file)
  const reader = response.body.getReader()
  const result = await reader.read() // raw array
  const decoder = new TextDecoder('utf-8')
  return decoder.decode(result.value) // the txt
}

export async function getDataFromCsv (file, options = { delimiter: ',', rowCallback: null }) {
  const {
    delimiter = ',',
    rowCallback = null
  } = options || {}
  // await new Promise(resolve => setTimeout(resolve, 5000))
  // const txt = await getDataFromTxt(file)
  const results = await new Promise(resolve => {
    Papa.parse(file, { header: true, skipEmptyLines: true, delimiter, dynamicTyping: true, download: true, encoding: 'utf-8', complete: resolve }) // object with { data, errors, meta }
  })
  if (results?.errors?.length > 0) console.log('results.errors', file.match(/[^/]+$/)?.[0], results?.errors)
  if (results?.data?.length < 5) console.log('results.data.length < 5', file.match(/[^/]+$/)?.[0], results?.data?.length)
  const rows = results.data // array of objects
  // rows.pop()
  return rows.map((r, idx) => {
    const keys = Object.keys(r)
    for (const k of keys) {
      let val = r[k]
      if (typeof val === 'string') {
        val = val.trim()
        if (+val === +`${+val}`) val = +val // map string to number if it's a string number
      }
      r[k] = val
    }
    r.idx = idx
    if (rowCallback) rowCallback(r)
    return r
  })
}

const resultLineParser = (line, resultObj, mapper) => {
  const values = line
    .split('\t')
    .filter(v => v !== '')
    .map(v => typeof v === 'string' ? v.trim() : v)
  // console.log(line, values, mapper)
  // console.log(values.length)
  // means is genepool
  if (values.length === 2) {
    const [key, val] = values
    const mappedGP = mapper[key]
    if (!mappedGP) return console.warn('No gene pool defined for', key, mapper)
    resultObj[mappedGP] = +val
  }
  // means is culture
  if (values.length === 3) {
    const [country, culture, geneticDistance] = values
    const mappedCult = mapper[culture]
    if (!mappedCult) return console.warn('No culture defined for country: ', country, 'AND culture: ', culture, mapper)
    // console.log('mappedCult', mappedCult, +geneticDistance)
    resultObj[mappedCult] = +geneticDistance
  }
}

/**
 * Parses the content comming from the pipeline and transforms it to something
 * usefull for the frontend.
 */
export function parseContent (content, culturesConf, genePoolsConf) {
  const {
    beginnerGenePools,
    advancedGenepools
  } = separateAndOrderBeginnerAndAdvancedCulturesAndGenePools(culturesConf, genePoolsConf)
  beginnerGenePools.sort((a, b) => a.result_order_bgn - b.result_order_bgn)
  advancedGenepools.sort((a, b) => a.result_order_adv - b.result_order_adv)
  const beginnerResultMapper = {}
  beginnerGenePools.forEach((gp, idx) => (beginnerResultMapper[idx + 1] = gp.name))
  const advancedResultMapper = {}
  advancedGenepools.forEach((gp, idx) => (advancedResultMapper[idx + 1] = gp.name))
  // console.log('advancedGenepools', advancedGenepools)
  // console.log('beginnerGenePools', beginnerGenePools)
  // console.log('genePoolsConf', genePoolsConf)

  // create object with properties the culture names and values the culture names
  // if the culture has an alternative name, then the value will be the culture name
  const geneticDistMapper = {}
  culturesConf.forEach(c => {
    // console.log('c', c)
    geneticDistMapper[c.Culture] = c.Culture
    if (c.alternative_resultname) geneticDistMapper[c.alternative_resultname] = c.Culture
  })

  const beginnerResult = {}
  const advancedResult = {}
  const beginnerGDistanceResult = {}
  const advancedGDistanceResult = {}

  // console.log()

  let isBeginner = true
  const lines = content.trim().split('\n')
  if (lines) {
    let block
    for (const line of lines) {
      if (line.startsWith('====')) {
        block = line.trim()
        continue
      }
      if (!block || line.trim() === '' || line.includes('genetic_dist')) continue
      if (block.includes('==========') && block.includes('GP_beginner')) {
        // console.log('in here', 'GP_beginner')
        resultLineParser(line, beginnerResult, beginnerResultMapper)
      } else if (block.includes('==========') && block.includes('GP_advanced')) {
        // console.log('in here', 'GP_advanced')
        resultLineParser(line, advancedResult, advancedResultMapper)
        isBeginner = false
      } else if (block.includes('==========') && block.includes('ClosestCulture_beginner')) {
        // console.log('in here', 'ClosestCulture_beginner')
        resultLineParser(line, beginnerGDistanceResult, geneticDistMapper)
      } else if (block.includes('==========') && block.includes('ClosestCulture_advanced')) {
        // console.log('in here', 'ClosestCulture_advanced')
        resultLineParser(line, advancedGDistanceResult, geneticDistMapper)
      }
    }
  }

  return {
    beginnerResult,
    advancedResult,
    beginnerGDistanceResult,
    advancedGDistanceResult,
    isBeginner
  }
}

export const isNumber = (nb) => +`${nb}` === nb
