import React, { useContext /*, useState, useEffect */ } from 'react'
// import HorizontalBarChart from "../horizontal-bar-chart"
import HorizontalBarChartComparision from '../horizontal-bar-chart-comparision'
import { PreviewContext } from '../preview-page'
import Modal from 'react-modal'
import { useLocation } from 'react-router-dom'

function MyResultsWindow (props) {
  const { show } = props
  const location = useLocation()
  const {
    genePoolsConf,
    isMobile,
    report,
    setShowMyResults
  } = useContext(PreviewContext)

  if (show && report && report.result) {
    const labels = genePoolsConf.map(gpc => gpc.name)
    const colors = genePoolsConf.map(gpc => gpc.color)
    const icons = genePoolsConf.map(gpc => gpc.smallIcon)
    const resultPercentages = labels.map((l) => +report.result[l])

    const content = (
      <div className='detwind detwind-my-results'>
        <div className='detwind-header'>
          <div className='detwind-header-title'>
            <span>My Results</span>
          </div>
          <div className='flex'>
            <div className='mr3 pointer icofont-before icofont-close' onClick={() => setShowMyResults(false)} />
          </div>
        </div>
        <div className='detwind-main'>
          <div className='detwind-main-cont'>
            <div className='detwind-title'>
              <span>Your Gene Pool Composition</span>
              <a className='detwind-link link icofont-before icofont-file-text' rel='noopener noreferrer' target='_blank' href={`/gene-pools${location.search ?? ''}`}>
                The origins of the Gene Pools
              </a>
            </div>
            <HorizontalBarChartComparision
              className='detwind-charts'
              labels={labels}
              colors={colors}
              icons={icons}
              percentagesB={resultPercentages}
            />
          </div>
        </div>
      </div>
    )

    return (isMobile ? <Modal isOpen>{content}</Modal> : content)
  } else {
    return null
  }
}

export default MyResultsWindow
