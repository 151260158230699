import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/Bell_beaker_lba_scotland_text_01.png'
import image02 from '../../assets/images/cultures/Bell_beaker_lba_scotland_text_02.jpg'

export const bellBeakerScotlandLBAPreview = 'The Scottish Bronze Age lasted from roughly 2100-750 BC, with the period of 1400–750 BC considered the Late Bronze Age and is characterized, among else, by the decline of the Beaker culture and the appearance of more advanced tools and weaponry in graves like the socketed axes and palstaves as well as Bronze xiphos swords (double-edged one-handed short-swords). This period is marked by the formal disappearance of the Beaker culture and the rise of the Atlantic Bronze Age and characterized by the high economic and cultural maritime exchange of goods and ideas in Western Europe and Britain. More items were now deposited in water bodies like lakes, rivers, and bogs. During this period, the Celts began to form in Europe as a new distinct Indo-European culture.'

function bellBeakerLBAScotland (props) {
  return (
    <>
      <h2>Post Bell Beaker culture at the Late Bronze Age in Scotland (<Years culture={props} />)</h2>
      <br />
      {bellBeakerScotlandLBAPreview}
      <br />
      <br />
      Some of the Scottish folk traditions have originated in this era. The burning of Yule log – called the Cailleach Nollich (the Old Wife) – derives from a female figure chalked onto the log. As the fire grew, it burns the old wife, and this “sacrifice” is believed to fight off deaths, according to the belief. The “Old wife” is no other than the Snake Goddess that during the Bronze Age became the embodiment of the prehistoric snake, a sacred creature whose life force empowers the surrounding world. The Snake Goddess had snake arms or snake hat decoration. In Pictish art, the Snake Goddess legs and hair twisted like snakes. The burning of a curvy log, was considered a sacrifice of the snake, thus allowing it to revive and bring the spring. The log’s ashes were believed to heal sick animals and improve the crops.
      <br />
      <br />
      <img src={image01} alt='The Post Bell Beaker culture at the Late Bronze Age in Scotland' />
      <legend> An illustration of people collecting a Yule log from Chambers Book of Days (1832) (Source: Wikipedia)</legend>
      <br />
      Some of the individuals included in this test were buried in sea caves, like the Covesea Caves, located on the southern coast of the Moray Firth in north-east Scotland. Some of the caves were dedicated to ritual and cultic activity, whereas others were used as burial sites and the remaining for habitation, signifying the importance that the Late Bronze Age assigned to waters. One of the adult females (45-60) was buried alongside a pig, perhaps as a food offering. Alongside one of the males, a small pottery vessel, a polished pebble, and a piece of worked pumice were found. One of the young adult females was found to have dental disease and lesions characteristic of cribra orbitalia and periostitis, caused by infection. This finding indicates the inaccessibility of medical knowledge of the inhabitants.
      <br />
      <br />
      <img src={image02} alt='The Post Bell Beaker culture at the Late Bronze Age in Scotland' />
      <legend> The Clava cairn is a type of Bronze Age circular chamber tomb cairn, named after the group of three cairns at Balnuaran of Clava, to the east of Inverness in Scotland. Some 50 cairns of this type can be found around Inverness. In the picture is a ring-type cairn at Balnauran of Clava (Source: Wikipedia)</legend>
      <br />
      Of the five males of the Beaker culture, three carried the Y-chromosome haplogroup R1b1a1a2a commonly found in Europe, Anatolia, the Caucasus, and its subbranches. One male carried the R haplogroup, whose origins are unclear and is found mainly in Eurasians, and the last male carried the haplogroup I2, which has originated in South Asia and is now common in West, South, Central, and East Asia. The mitochondrial haplogroups of the five males and four females included a similarly limited diversity of maternal haplogroups as observed in the Early Bronze Age and include H, I, K, and U. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup I dates to the Paleolithic Iran/Caucuses area and likely arrived with steppe peoples into Europe. Haplogroup K is common in Europe today and was recently derived from U, which was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/2H3zC82'>"Saxons, Vikings, and Celts: The Genetic Roots of Britain and Ireland"</a></strong>
      <br />
      <br />
      <iframe title='Saxons, Vikings, and Celts: The Genetic Roots of Britain and Ireland' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0393330753&asins=0393330753&linkId=60995266670d2e793e876b6a5416f176&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default bellBeakerLBAScotland
