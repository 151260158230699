import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Navbar from './navbar'
import Footer from './footer'
import { HashLink } from 'react-router-hash-link'
import '../styles/_culture-page.scss'
// import cultureIcon from '../assets/images/cultures/corded-ware.png'

const BackLink = () => {
  const location = useLocation()
  return <Link className='icofont-before icofont-long-arrow-left' to={{ pathname: '/cultures', search: location.search }}>To all cultures</Link>
}

function CulturesPageId (props) {
  const location = useLocation()
  const { cultures = [], cultureName, isPreview } = props

  const culture = cultures.find(c => c.linkName === cultureName)
  let content = null
  if (culture) {
    const Story = culture.story
    const isStringStory = typeof Story === 'string'
    const hasPreview = culture.preview || false

    content = (
      <div key={'cult-story-' + culture.idx} className='content-row'>
        <div className='content-block'>
          <BackLink />
          <div className='icon'><img src={culture.icon} alt='culture-icon' /></div>
          {/* <div className='t-b title'>{c.Culture}</div> */}
          {isPreview && hasPreview ? <div className='description' dangerouslySetInnerHTML={{ __html: hasPreview }} /> : null}
          {isPreview && !hasPreview && isStringStory ? <div className='description' dangerouslySetInnerHTML={{ __html: Story }} /> : null}
          {isPreview && !hasPreview && !isStringStory ? <div className='description'><Story {...culture} /></div> : null}
          {!isPreview && !isStringStory ? <div className='description'><Story {...culture} /></div> : null}
          {!isPreview && isStringStory ? <div className='description' dangerouslySetInnerHTML={{ __html: Story }} /> : null}
          {isPreview && hasPreview && <HashLink className='display-block mb15' to={{ pathname: '/#test', search: location.search }}>Buy the report to read the full story</HashLink>}
          <BackLink />
        </div>
      </div>
    )
  }

  return (
    <div className='cultres-page page-wrap'>
      <Navbar isApp={!isPreview} />
      {content}
      <Footer />
    </div>
  )
}

export default CulturesPageId
