import React from 'react'

export function Years (props) {
  const { culture = {} } = props
  return (
    <>
      {culture.From <= 0 && culture.To <= 0 && <span>{-culture.From} - {-culture.To} B.C.</span>}
      {culture.From <= 0 && culture.To > 0 && <span>{-culture.From} B.C - {culture.To} A.C</span>}
      {culture.From > 0 && culture.To > 0 && <span>{culture.From} - {culture.To} A.C</span>}
    </>
  )
}
