import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/yamnaya_text_01.png'
import image02 from '../../assets/images/cultures/yamnaya_text_02.jpg'
import image03 from '../../assets/images/cultures/yamnaya_text_03.jpg'
import image04 from '../../assets/images/cultures/yamnaya_text_04.jpg'

export const yamnayaPreview = 'The Pontic-Caspian steppe is the vast land stretching from the northern shores of the Black Sea, east to the Caspian Sea and west towards Ukraine. The area saw the rise of some of the greatest human civilizations like the equestrian Scythians and Sarmatians people as well as later cultures like the Huns, Khazars, Bulgars, and many other cultures who settled Europe and Western Asia. In prehistoric times, during the Bronze Age it was the turn of Kurgan cultures to dominate the steppe.'

function yamnaya (props) {
  return (
    <>
      <h2>Yamnaya culture (<Years culture={props} />)</h2>
      <br />
      {yamnayaPreview}
      <br />
      <br />
      <img src={image01} alt='The Yamnaya culture' />
      <legend> Wild horses in the the Pontic-Caspian steppe (source: Wikipedia)</legend>
      <br />
      The Yamnaya (or Yamna) culture flourished between the Late Copper (3300 BC) and Early Bronze (2600 BC) Ages in the Pontic-Caspian steppe, north of the Caspian and Black Seas and between the Southern Bug, Dniester, and Ural rivers. This culture, whose real name is unknown, is named after the pit burial chambers in the kurgans where the human remains were found.
      <br />
      <br />
      As other steppe cultures, the Yamnaya lived as nomads and managed large herds of goats and sheep and are related to other Steppe people who either remained in the steppe like the Sintashta, Andronovo, and Srubnaya cultures or moved westwards to Europe like the Corded Ware people and the Bell Beakers. It is thereby likely that they originated from the Indo-European hunter-gatherers and who roamed the steppes during the Copper Age. They used bones and wood as ornaments and copper and stone tools.
      <br />
      <br />
      <img src={image02} alt='The Yamnaya culture' />
      <legend> Yamna tools (source: Wikimedia Commons)</legend>
      <br />
      The Yamnaya buried their dead in kurgan cemeteries along river valleys. The Don-Volga steppe graves contained mainly goat and sheep bones with some cattle, horse, and dog, but no pig, whereas the lower Don settlement contained pig but no dog bones, indicating differences in the mobility and herding patterns of different Yamnaya groups. Unsurprisingly, they ate little cultivated grains and their diet was based mostly on meet and wild seeds and tubers.
      <br />
      <br />
      <img src={image03} alt='The Yamnaya culture' />
      <legend> Yamna arrows (source: Wikimedia Commons)</legend>
      <br />
      Dieting habits were not what the Yamnaya were known for. It was their restless spirit and explorers’ blood that made them forever an integral part of Europe. At some point, the Yamnaya packed their little belonging on their wagons and, after allowing their horses to graze the steppe grass for the last time, they made their way to Europe in massive numbers, reshaping the European gene pools that until then represented mostly European hunter-gatherers living in small communities. In our test, the Yamana gene pool has been identified and can be seen among the members of the Corded Ware, Srubnaya, Andronovo, Potapovka, Únětice, and Bell Beakers.
      <br />
      <br />
      <img src={image04} alt='The Yamnaya culture' />
      <legend> Yamnaya Corded Ware (source: Wikimedia Commons)</legend>
      <br />
      The 17 individuals included in the test were found in different sites, mostly in Russia with two individuals found in Kazakhstan and Bulgaria. The 13 males parental haplogroups are I2, and R. Haplogroup I2 originated in Europe and is common in Eastern Europe and the Balkan. The paternal R haplogroup originated approximately 27,000 years ago in Central or South Asia and has since split to many subclades that are found today in Europe, Central Asia, and South Asia. The maternal haplogroups are very diverse and consists of H, R, T, U, and W. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup R is one of the most ancient haplogroups, originating some 65,000 years ago, and is associated with the peopling of Eurasia. Haplogroup T is probably Middle Eastern in origin and found today throughout Central Asia and in low frequencies in Europe. Haplogroup U was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup W is one of the few haplogroups that have originated in Western Asia some 23,000 years ago. It is found in Europe, Western and Southern Asia and exists in high frequencies in Pakistan, Turkey, and the Balkan.
      <br />
      <br />
      The Yamnaya culture declined in Asia quickly as they absorbed in the general population. In Europe, they were replaced by the Romans and in the Pontic Caspian steppes they were replaced by the Bronze Age Catacomb culture, named after its more elaborated burial practices. For instances, in Catacomb graves the shaft graves included also a burial niche at their base and often included a wheeled wagon. Cattle sacrifices were also more common, indicating the growth of complexity in cultic activities.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/3lRB1gC'>"The Horse, the Wheel, and Language: How Bronze-Age Riders from the Eurasian Steppes Shaped the Modern World"</a></strong>
      <br />
      <br />
      <iframe title='The Horse, the Wheel, and Language: How Bronze-Age Riders from the Eurasian Steppes Shaped the Modern World' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0691058873&asins=0691058873&linkId=5480f00ad26d95afac41b4c1a5874c2b&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default yamnaya
