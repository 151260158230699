import React, { Fragment, useContext } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import ScrollArrow from './scroll-arrow'
import AppContext from './app-context'

function FaqPage (props) {
  const { faqs = [] } = props
  const { geneticTestConfig, isPreview } = useContext(AppContext)

  faqs.forEach((f, i) => (f.id = i))
  const sections = [...new Set(faqs.map(f => f.section))]

  const refs = faqs.reduce((acc, f) => {
    acc[f.id] = React.createRef()
    return acc
  }, {})

  const handleClick = (id) => refs[id].current.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })

  const {
    bgImg
  } = (geneticTestConfig || { faqpage: { } }).faqpage

  return (
    <div className='faq-page faq-wrap page-wrap faq'>
      <Navbar isApp={!isPreview} />
      <ScrollArrow />
      <div className='page-header-wrap faq-head' style={{ backgroundImage: `url("${bgImg}")` }}>
        <div className='content'>
          <div className='page-title'>
            <h1>FREQUENTLY ASK QUESTION
              <span />
            </h1>
          </div>
        </div>
      </div>
      <div className='grid faq-section section-text'>
        {sections.map((s, idx) =>
          <div key={'faq-section-' + idx} className='col-md-offset-3 col-md-6 section-text'>
            <h4>{s}</h4>
            <ul className='section-text-toc'>
              {faqs.filter(f => f.section === s).map(f => <li key={'faq-' + f.id} onClick={() => handleClick(f.id)}>{f.question}</li>)}
            </ul>
          </div>)}

        {sections.map((s, idx) =>
          <div key={'faq-section-cont' + idx} className='col-md-offset-3 col-md-6 section-text'>
            <h4>{s}</h4>
            {faqs.filter(f => f.section === s)
              .map(f => <Fragment key={'faq-cont-' + f.id}><h5 ref={refs[f.id]}>{f.question}</h5><p dangerouslySetInnerHTML={{ __html: f.answer }} /></Fragment>)}
          </div>)}

      </div>
      <Footer />
    </div>
  )
}

export default FaqPage
