import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/Corded_ware_germany_text_01.png'
import image02 from '../../assets/images/cultures/Corded_ware_germany_text_02.jpg'
import image03 from '../../assets/images/cultures/Corded_ware_germany_text_03.jpg'

export const cordedWareGermanyPreview = 'The Corded Ware culture in Europe lasted from the Late Neolithic to the Early Bronze Age (2900-2350 BC). The term Corded Ware is derived from ceramic vessels made by these people that were decorated by using making impressions using ropes or ‘cords.’ These ceramic vessels, the mark of this culture, have been found in burial sites throughout Europe. The Corded Ware culture existed alongside other Bronze Age cultures like the Single Grave and Battle-Axe cultures.'

function cordedWareGermany (props) {
  return (
    <>
      <h2>Corded Ware culture in Germany (<Years culture={props} />)</h2>
      <br />
      {cordedWareGermanyPreview}
      <br />
      <br />
      <img src={image01} alt='The Corded ware in germany culture' />
      <legend> Corded Ware culture (source: <a href='https://commons.wikimedia.org/wiki/File:Corded_Ware_culture.png#/media/File:Map_Corded_Ware_culture-en.svg'>Wikimedia Commons</a>)</legend>
      <br />
      The appearance of the Corded Ware folk in Europe has proven to be an enigmatic period in the history of the continent. This remarkably uniform culture spread relatively quickly across broad swathes of the continent from the Volga (east) to the Rhine (west) rivers and up to Sweden and Finland in the north. They have originated in the Pontic Steppe region in today’s Ukraine and Russia and between the Caspian and Black Seas from the Yamnaya people. Both the Yamnaya and Early Corded Ware people were nomadic herders. Their diet consisted of fish, dairy, and meat, and they practiced very little farming.
      <br />
      <br />
      Many of the Corded Ware people were non-European migrants. They mixed with the earlier Neolithic peoples they met in Europe, with some evidence suggest that conflicts emerged between these two groups. The Corded Ware people have introduced the Indo-European languages to Europe, which is the earliest form of most of the languages spoken in Europe today.
      People were often buried with a variety of other grave goods, such as stone battle axes, pendants, and bone plates.
      <br />
      <br />
      <img src={image02} alt='The Corded ware in germany culture' />
      <legend> Corded Ware stone-axe (2800-2400 BC).</legend>
      <br />
      Corded Ware people lived in a time of great changes where dramatic technological advancements took place. Though their ancestors often relied on stone tools, over time, the Corded Ware people began to use copper and eventually learned how to make bronze, by adding tin to copper.
      <br />
      <br />
      <img src={image03} alt='The Corded ware in germany culture' />
      <legend> Silex blade and bone needle from grave 15 of the Corded ware culture.</legend>
      <br />
      Bronze was a harder and cheaper metal than copper, and it revolutionized society. The discovery of bronze allowed people to develop more efficient tools and deadlier weapons, which gave them an advantage in the battlefield and allowed them to spread their ideas and beliefs further on.
      <br />
      <br />
      Women in Corded Ware Culture were extremely mobile, oftentimes marrying outside their social group and increasing the biodiversity of the groups. A Corded Ware woman would move to her husband’s territory, introducing her ideas of diet, herbs, and other beliefs to the new group.
      <br />
      <br />
      This test includes one man and three women found in Tiefenbrunn and Bergrheinfeld. The main paternal R haplogroup originated approximately 27,000 years ago in Central or South Asia and has since split to many subclades that are found today in Europe, Central Asia, and South Asia. The maternal haplogroups belong to J and U.
      <br />
      <br />
      Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup U was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/2SYsGLL'>"Stereotype: The role of grave sets in Corded Ware and Bell Beaker funerary practices"</a></strong>
      <br />
      <br />
      <iframe title='corded ware germany book' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=9088909393&asins=9088909393&linkId=b2d2f23b7627a2f219bef280ac32ec03&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default cordedWareGermany
