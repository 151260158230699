import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Navbar from './navbar'
import Footer from './footer'
import ScrollArrow from './scroll-arrow'
import '../styles/_culture-page.scss'
import AppContext from './app-context'
import { Years } from './years'
import isEmpty from 'lodash.isempty'

function CulturesPage (props) {
  const location = useLocation()
  const { cultures = [] } = props
  const localCultures = [...cultures]
  localCultures.sort((a, b) => -b.From - -a.From)

  const { geneticTestConfig, isPreview } = useContext(AppContext)

  if (isEmpty(geneticTestConfig)) return null

  const {
    title,
    description,
    bgImg
  } = (geneticTestConfig || { cp: { } }).cp

  return (
    <div className='cultres-page page-wrap'>
      <Navbar isApp={!isPreview} />
      <ScrollArrow />
      <div className='page-header-wrap culture-head' style={{ backgroundImage: `url(${bgImg})` }}>
        <div className='content'>
          <div className='page-title'>
            <h1>{title}</h1>
          </div>
        </div>
      </div>
      <div className='content-row gene-info' style={{ backgroundColor: 'transparent' }}>
        <div className='content-block'>
          <div className='description t-justify'>
            {description}
          </div>
        </div>
      </div>
      <div className='grid section-text'>
        <div className='col-md-offset-3 col-md-6'>
          <ul className='section-text-toc pt30 pb30'>
            {localCultures.map((c) =>
              <li key={'cult-toc-' + c.idx}>
                <Link to={{ pathname: '/cultures/' + c.linkName, search: location.search }}>
                  <span className='flex align-center flex--grow'>
                    <img className='mr5' src={c.smallIcon} alt='culture-icon' />
                    <span>{c.Culture}</span>
                  </span>
                  <Years culture={c} />
                </Link>
              </li>)}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CulturesPage
