import React, { useContext, useState, useEffect } from 'react'
import { Map, TileLayer, ZoomControl, Marker } from 'react-leaflet'
import { PreviewContext } from '../preview-page'
import AppContext from '../app-context'
import AncestryCluster, { iconMarker } from './ancestry-clusters'

const originalCenter = [18, 0.42]

function AncestryMap (props) {
  const { startZoom = 2, enableZoom = true, culture, style = {} } = props
  const {
    sidebarClickedCulture,
    auto,
    isMobile
  } = useContext(PreviewContext)

  const [zoom, setZoom] = useState(startZoom)
  const [center, setCenter] = useState(originalCenter)
  const { geneticTestConfig } = useContext(AppContext)

  let {
    map
  } = (geneticTestConfig || { map: { overlay: 'rgba(0, 112, 206, 0.7)' } })

  map = map || {}
  map.overlay = map.overlay || 'rgba(0, 112, 206, 0.7)'

  // useTraceUpdate({
  //   sidebarClickedCulture,
  //   auto
  // })

  useEffect(() => {
    if (auto) {
      isMobile ? setZoom(0) : setZoom(startZoom)
      setCenter(originalCenter)
    } else if (sidebarClickedCulture) {
      if (zoom < 5) setZoom(5)
      setCenter([sidebarClickedCulture.Lat, sidebarClickedCulture.Long])
    }
  }, [sidebarClickedCulture, auto, isMobile])

  return (
    <Map
      style={style}
      center={center}
      zoom={zoom}
      zoomControl={false}
      maxZoom={12}
      minZoom={isMobile ? 0 : 2}
      onViewportChanged={(e) => setZoom(e.zoom)}
    >
      <style type='text/css'>
        {`
          .leaflet-overlay-pane {
            background-color: ${map.overlay};
          }
        `}
      </style>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {enableZoom && <ZoomControl position='bottomright' />}
      {enableZoom
        ? <AncestryCluster zoom={zoom} />
        : <Marker key={'culture-' + culture.idx} position={[culture.Lat, culture.Long]} icon={iconMarker} />}
    </Map>
  )
}

export default AncestryMap

// function useTraceUpdate (props) {
//   const prev = useRef(props);
//   useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log('Changed props:', changedProps);
//     }
//     prev.current = props;
//   });
// }
