import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/ain_ghazal_text_01.jpg'
import image02 from '../../assets/images/cultures/ain_ghazal_text_02.jpg'
import image03 from '../../assets/images/cultures/ain_ghazal_text_03.jpg'
import image04 from '../../assets/images/cultures/ain_ghazal_text_04.jpg'
import image05 from '../../assets/images/cultures/ain_ghazal_text_05.jpg'

export const ainGhazalPreview = 'Ain Ghazal is located on the outskirts of today’s Amman. Its humble location and appearance disclose very little of the drama that encompassed it during the early days of our civilization. The Mesopotamian Bronze Age archives dating from the fourth and third millennium BC include a rich depository of text written in the cuneiform script. From the story of Gilgamesh and tells of the flood to legal and administrative documents, these scripts outlie the knowledge, beliefs, and cultic practices of Mesopotamian people as they moved out of the Neolithic and harnessed Bronze.'

function ainGhazal (props) {
  return (
    <>
      <h2>The ‘Ain Ghazal Canaanite culture in Jordan (<Years culture={props} />)</h2>
      <br />
      {ainGhazalPreview}
      <br />
      <br />
      <img src={image01} alt='The ‘Ain Ghazal Canaanite culture in Jordan' />
      <legend> Mountain Gazelle (Source: Wikipedia)</legend>
      <br />
      The appearance of agriculture in the Levant, marks the beginning of the Neolithic period in 10,300 BC. ‘Ain Ghazal ("Spring of the Gazelle") was founded almost a thousand years later. Due to the favorable environmental conditions that include the persistent presence of water from the spring of ‘Ain Ghazal and the permanent Zarqa River, the second largest tributary to the lower Jordan River. It flows in the Zarqa Valley, which connected the Eastern Desert with the Jordan Valley. ‘Ain Ghazal existed as a permanent settlement throughout the Neolithic period being a home to around 3,000 villagers at its peak until about 5,750 BC when it was mysteriously abandoned.
      <br />
      <br />
      <img src={image02} alt='The ‘Ain Ghazal Canaanite culture in Jordan' />
      <legend> The Jordan river (source: Wikipedia)</legend>
      <br />
      Interestingly, the Zarqa River is identified with the Biblical river Jabbok, which Jacob crossed on his way to Canaan, after leaving Harran (in Turkey). The Jordan was not kind to Jacob.
      <br />
      <br />
      <img src={image03} alt='The ‘Ain Ghazal Canaanite culture in Jordan' />
      <legend>  Jacob Wrestling with the Angel (Rembrandt, 1659/60) (source: Wikipedia)</legend>
      <br />
      On the one riverbank, he had to fight with the Angel of God all night and the next day he had to spend his fortune to appease his brother to avoid fighting him and his 400 warriors who waited for him on the opposite riverbank.
      <br />
      <br />
      <img src={image04} alt='The ‘Ain Ghazal Canaanite culture in Jordan' />
      <legend> Esau and Jacob reconcile (1844) by Francesco Hayez (source: Wikipedia)</legend>
      <br />
      On its west the river leads to the Sukkot Valley, mentioned many times in the Bible. Jacob crossed Sukkot Valley and Jordan and reached Shechem, which later became a cultic center in Samaria. At the mouth of the valley are the Biblical cities Zaretan and Adam, both mentioned in the book of Joshua. Zaretan stood near the location where the Hebrews crossed the Jordan. The City of Adam stood very close to Zaretan and is where the flow of the water was arrested and rose up “upon an heap” to allow the Hebrews a passage.
      <br />
      <br />
      The ‘Ain Ghazal Canaanites have witnessed the most exciting transformations of their time. They saw the rise of Egypt, formation of Judea, and the establishment of Biblical kingdoms like Moab and Edom, which they were likely part of. They passed on this information, through texts and symbols that captured the knowledge that they have accumulated over 2500 years when their culture went extinct. Or so thought the archeologists for quite sometimes.
      <br />
      <br />
      During the 1982 season of excavations at the Pre-Pottery Neolithic Site of ‘Ain Ghazal, the project’s director, discovered a small Early Bronze Age site at the intersection of a small running wadi and Wadi Zarka. It was situated about 450m south of the Neolithic town and was dated to 2300-1960 BC (the Biblical period spanning Abraham to Joseph). The position of the settlement indicated little concern from invasion, perhaps due to the remoteness of the site and the peaceful condition of that era. The site had several favorable conditions, such as access to clean water, fertile lands, timber, and clay that could be used for ceramics. Indeed, the ceramics in the site were home-made. The two houses found also contained a jar rim created using a wheel and fired at temperature of over 1000 degrees Celsius.
      <br />
      <br />
      The use of strong materials, wheel, fire, and chemical found abundantly in the region allowed the performance if miraculous acts that that found their way to religious texts and influenced the imagination of the Bronze Age people who began writing the first chapter of our civilization. These chapters not only provided answers to everlasting questions such as who we are and how did we get here, but also provided instructions as to how control nature and its phenomenon using figurines and other magical means. This knowledge was sacred and only accessible to the forming privileged class that could muster the art of coding information in symbols and encoding it back. Remarkably, in Mesopotamia the shapes and meaning of the pictographic signs of 3100 BC perpetuated those used in the Neolithic. In other words, the Bronze Age symbols of “wheat” “sun” and “house” still resembled those used at ‘Ain Ghazal in 4000 years earlier.
      <br />
      <br />
      <img src={image05} alt='The ‘Ain Ghazal Canaanite culture in Jordan' />
      <legend> Legend: Example of proto-cuneiform clay tablet with pictograms (source: Wikipedia)</legend>
      <br />
      The expedition also found the remains of two males and one female in a high burial cave. Since high places had religious significance, in being close to the gods of heaven, individuals buried on high mountains (like Moses, Aaron, and Job) were those who played leadership and ritualistic roles. From there, their souls were believed to find the way to the heaven.
      <br />
      <br />
      A genetic analysis of ‘Ain Ghazal ancient inhabitants reveal that they were Canaanites who shared most of their ancestry with Neolithic Levantines. The two males carried the Y-chromosome haplogroup J1, which is still common in Levantine populations today. Their mitochondrial haplogroups included H, X and R. Haplogroup H originated in the Middle East and is commonly found in Europe today. Both X and R have very ancient ancestry; X is now common in West Asia, whilst R is common across the globe.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/37aW3Ty'>"The spring of the Gazelle An early Neolithic society - Ain Ghazal: From Antiquity to Modernity - Ayn Gazalle civilization"</a></strong>
      <br />
      <br />
      <iframe title='corded ware germany book' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=6202562897&asins=6202562897&linkId=3eb0c40546020d5d50674888443eaf2f&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default ainGhazal
