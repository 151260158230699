import { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { withRouter, useLocation } from 'react-router-dom'

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick () {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
  }, [isClient])

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) return false

    function handleResize () {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isClient, getSize])

  return windowSize
}

export function usePrevious (value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

function _ScrollToTop ({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      // console.log('scroll to top')
      window.scrollTo(0, 0)
    })
    return () => unlisten()
  }, [history])

  return (null)
}

export function useKeyPress (targetKey, action) {
  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler ({ key }) {
      if (key === targetKey) action()
    }
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => window.removeEventListener('keydown', downHandler)
  }, [action, targetKey]) // Empty array ensures that effect is only run on mount and unmount
}

export const ScrollToTop = withRouter(_ScrollToTop)

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery () {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export function useGeneticTestQueryParameter (queryParamName = 'gt') {
  const query = useQuery()
  const geneticTest = query.get(queryParamName)
  return geneticTest
}
