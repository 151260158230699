import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/south-western_iberian_text_01.jpg'
import image02 from '../../assets/images/cultures/south-western_iberian_text_02.jpg'
import image03 from '../../assets/images/cultures/south-western_iberian_text_03.jpg'
import image04 from '../../assets/images/cultures/south-western_iberian_text_04.jpg'

export const southWesternIberianBronzePreview = 'The South-Western Iberian Bronze culture is the Bronze Age culture that developed in Southern Portugal and surrounding regions in Spain around 3700 BC, where the Megalithic people previously lived. The megalith culture was characterized by the erection of large stone structures, dolmens, or monuments, like the ones in Stonehenge, Göbekli Tepe, or Anta de Adrenunes. With over 35,000 such complexes in Europe alone, the end of the megalithic builders came when Caucasus horse herders first mixed copper with tin.'

function southWesternIberian (props) {
  return (
    <>
      <h2>South-Western Iberian Bronze culture of Portugal (<Years culture={props} />)</h2>
      <br />
      {southWesternIberianBronzePreview}
      <br />
      <br />
      <img src={image01} alt='The South-Western Iberian Bronze culture of Portugal' />
      <legend> a necropolis in the Anta de Adrenunes monument (source: Wikipedia)</legend>
      <br />
      Unlike the burial sites of the Megaliths, the SW Iberian Bronze people used a coffin-like stone box to bury their dead alongside a bronze knife.
      <br />
      <br />
      The original Southern Iberian Peninsula communities did not develop an economic system where agricultural products were exchanged on a wide-scale, suggesting that the population consisted of hunter-gatherers, fishermen, and only small scale herders. Only hundreds of years later did the society adopt a simple barter system based on farming, like in the Mediterranean. This new system improved the production, and urban centers started to grow as surpluses accumulated. This sudden richness created new social classes of bandits and warriors that surrounded the urban centers and herds alongside more natural threats to food production, like the Iberian wolves and other predators. Today, 200-300 wolves remain in Portugal and their range is limited to the north and center of the country.
      <br />
      <br />
      <img src={image02} alt='The South-Western Iberian Bronze culture of Portugal' />
      <legend> Alpha male Iberian wolf after a meal (source: Wikipedia)</legend>
      <br />
      The major sites of the SW Iberian Bronze culture include Casas Velhas (Melides, Setúbal), which is considered the best-preserved site in Portugal for the SW Iberian Bronze culture, and the Monte da Cabida 3 (São Manços, Évora). In all those sites, humans remains were found cramped in small stone boxes and pits by themselves or with up to four people. In some graves, funerary ceramic vessels, metallic objects, and faunal remains were found. Gruta do Medronhal (Arrifana, Coimbra) does not have cists, but rather have natural caves that served for burial.
      <br />
      <br />
      <img src={image03} alt='The South-Western Iberian Bronze culture of Portugal' />
      <legend> An example of a cist (source: Wikimedia Commons).</legend>
      <br />
      Like the Megalithic culture, the end of the SW Iberian Bronze culture was also prompted by technological and cultural innovations, this time from the Mediterranean World when the first Phoenician boats have landed along the Iberian shores, and the settlers began founding their colonies around 750 BC.
      <br />
      <br />
      <img src={image04} alt='The South-Western Iberian Bronze culture of Portugal' />
      <legend> Writing in the Bronze Age in Portugal (source: Wikipedia).</legend>
      <br />
      This test comprises of seven individuals. The two men have the Y haplogroup R1b1a1a commonly found in Europe, Anatolia, the Caucasus, and its subbranches. The seven mitochondrial haplogroups are highly diverse and include H, J, U, and V. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup I dates to the Paleolithic Iran/Caucuses area and likely arrived with steppe peoples into Europe. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup U was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup V has a very ancient Iberian ancestry occurring in only 4% of Europeans. It has spread during the Neolithic and has reached North Africa.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/349fOZv'>"The Rock Art of Spain and Portugal: A Study of Conceptual Anthropology"</a></strong>
      <br />
      <br />
      <iframe title='The Rock Art of Spain and Portugal: A Study of Conceptual Anthropology' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=B00OJBX896&asins=B00OJBX896&linkId=e8dc3d536392b68564685ecb805eaffb&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default southWesternIberian
