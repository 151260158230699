import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PreviewContext } from './preview-page'
import AppContext from './app-context'

function Navbar (props) {
  const [navbarShow, setNavbarShow] = useState(false)
  const {
    className = '',
    isApp = false
  } = props
  // const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const { setNavbarHeight } = useContext(PreviewContext) || {}
  const location = useLocation()
  const { geneticTestConfig } = useContext(AppContext)

  const {
    title
  } = (geneticTestConfig || { navbar: {} }).navbar

  useEffect(() => {
    // setHeight(ref.current.clientHeight)
    if (setNavbarHeight) setNavbarHeight(ref.current.clientHeight)
  }, [ref, setNavbarHeight])

  const menuContent = (
    <>
      <li><Link to={{ pathname: '/', search: location.search }}>Home</Link></li>
      {!isApp && <li><Link to={{ pathname: '/preview', search: location.search }}>Preview</Link></li>}
      {isApp && <li><Link to={{ pathname: '/app', search: location.search }}>My results</Link></li>}
      <li><Link to={{ pathname: '/cultures', search: location.search }}>Cultures</Link></li>
      <li><Link to={{ pathname: '/gene-pools', search: location.search }}>Gene Pools</Link></li>
      <li><Link to={{ pathname: '/faq', search: location.search }}>FAQ</Link></li>
    </>
  )

  return (
    <div className={className + ' navbar_wrap'} ref={ref}>
      <div className='logo'>
        {!isApp && <Link to={{ pathname: '/', search: location.search }}>{title}</Link>}
      </div>
      <div className='menu'>
        <div className='nav_right'>
          <div className='icon desktop-hide'>
            <button onClick={() => setNavbarShow(!navbarShow)}>
              <span />
              <span />
              <span />
              <span />
            </button>
            {
              navbarShow
                ? <ul className='mobile-menu'>{menuContent}</ul>
                : null
            }
          </div>
          <ul className='mobile-hide'>
            {menuContent}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar
