import React, { useContext } from 'react'
import Modal from 'react-modal'
import { PreviewContext } from '../preview-page'

function CulturesWindow (props) {
  const { show, culturesConf } = props
  const { setShowCultures, isMobile, setSidebarClickedCulture, setLocalTimeIfLocalTimeIsSmaller } = useContext(PreviewContext)

  if (show) {
    const content = (
      <div className='cult-wind'>
        <div className='cult-wind-header'>
          <div className='cult-wind-header-title pb10' />
          <div className='mr3 pointer icofont-before icofont-close' onClick={() => setShowCultures(false)} />
        </div>
        <div className='cult-cont'>
          {culturesConf.map(c => <img src={c.smallIcon} alt={'cult-wind-icon-' + c.idx} key={'cult-wind-icon-' + c.idx} onClick={() => { setShowCultures(false); setLocalTimeIfLocalTimeIsSmaller(-+c.From); setSidebarClickedCulture(c, true) }} />)}
        </div>
      </div>
    )

    return (isMobile ? <Modal isOpen>{content}</Modal> : content)
  } else {
    return null
  }
}

export default CulturesWindow
