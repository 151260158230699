import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/Bell_beaker_mba_wales_text_01.jpg'
import image02 from '../../assets/images/cultures/Bell_beaker_mba_wales_text_02.jpg'
import image03 from '../../assets/images/cultures/Bell_beaker_mba_wales_text_03.jpg'

export const bellBeakerWalesMBAPreview = 'The Welsh Bronze Age lasted from roughly 2100-650 BC, with a period of 1400-800 BC considered the Middle Bronze Age. This period saw the formal end of the Beaker culture and the rise of the Atlantic Bronze Age. It is characterized by the high economic and cultural maritime exchange of goods and ideas in Western Europe and Britain. More items were now deposited in water bodies like lakes, rivers, and bogs. During this period, the Celts began to form in Europe as a new distinct Indo-European culture.'

function bellBeakerMBAWales (props) {
  return (
    <>
      <h2>Post Bell Beaker culture at the Middle Bronze Age in Wales (<Years culture={props} />)</h2>
      <br />
      {bellBeakerWalesMBAPreview}
      <br />
      <br />
      During this time, the Welsh improved their mining techniques and became the main copper suppliers for the rest of Britain, superseding the Scottish copper. This process expedited the decline of Scottish Beaker and attracted adventurers and traders to Wales, particularly to Great Orme, who brought their tools and technology with them. By the end of the Middle Bronze Age, the field system characterized by the long boundary walls emerged, and as surpluses accumulated and created social and economic inequalities, the first hillforts were built for protection as tribal kingdoms started to emerge throughout Britain.
      <br />
      <br />
      <img src={image01} alt='The Post Bell Beaker culture at the Middle Bronze Age in Wales' />
      <legend> Original stone with serpentine design, found within the chamber in Byn Celli Ddu, now in the National Museum of Wales (Source: Wikipedia)</legend>
      <br />
      It was also at that time that some of the great menhirs (standing stones) were erected throughout Wales in places like Stackpole Warren, Rhos y Clegyrn, and near Trellech. The menhirs represented the Mother-Goddess and, to this day, is thought to cure humans and animals of illnesses. As the stone is the embodiment of the Goddess, it is called in some places Lady of the stony ground, and the land on which they are erected is considered sacred. Legends say that the stones can walk, dance, and speak. Every other year there is a report involving fidgety stones. Dances around prehistoric circles, sometimes with a menhir in the middle, are thought to create a powerful instrument capable of overcoming free will and tearing a man apart. Such dances have been historically associated with fairies who served the Goddess. Stories of the late 20th century depict women dancing around wells.
      <br />
      <br />
      <img src={image02} alt='The Post Bell Beaker culture at the Middle Bronze Age in Wales' />
      <legend> Maen Llia, a standing stone which sits on moorland at Culver Hole, Port Eynon (Source: Wikipedia)</legend>
      <br />
      One of the women (4-18 years old) included in this test was found in the North Face Cave (Llandudno, Wales). Besides her was a single amber bead. The second woman was found in Culver Hole Cave, a sea cave used for mass burials in Port Eynon (West Glamorgan, Wales). The cave also contained domesticated animal bones and pottery. The cave has been used continuously from the Middle-Late Bronze Age to the Roman period. During the latter period, it was used as a shrine, as evidenced by the bronze goddess figurine found in the cave.
      <br />
      <br />
      <img src={image03} alt='The Post Bell Beaker culture at the Middle Bronze Age in Wales' />
      <legend> Culver Hole, Port Eynon (Source: Wikimedia commons)</legend>
      <br />
      These females carried the maternal haplogroups U and H. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup U was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/3nTolHZ'>"A Concise History of Wales"</a></strong>
      <br />
      <br />
      <iframe title='A Concise History of Wales' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0521530717&asins=0521530717&linkId=338c48e60c7fa4c4ea389f3b42e4d00f&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default bellBeakerMBAWales
