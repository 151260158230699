import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/bell_beaker_spain_text_01.jpg'
import image02 from '../../assets/images/cultures/bell_beaker_spain_text_02.jpg'

export const bellBeakerSpainPreview = 'People from the Bell Beaker culture (2800-1800 BC) had already spread through Europe from the Iberian Peninsula (where they probably originated as bronze traders) and Sicily to the Danube before they arrived in Andalusia, Spain (2500-2200 BC). The Bell Beakers are one of the groups who introduced the Steppe ancestry onto Europe and have later landed in Britain. Though they shared genetic similarity with other European Bell Beakers, they are not identical to them. We can thereby surmise that as the Bell Beaker people crossed Europe, they peacefully interacted with the locals they encountered, sharing ideas and technology and mixed with them. Typically to Bell Beakers, the Iberian Beaker people also preferred coastal regions from where they sailed, traded, and exchanged ideas and beliefs. Despite their association with many artifacts and practices, including the use of metal, it is their distinctive pottery that they are most famous for. These potteries were highly decorated vessels shaped like an upside-down bell.'

function bellBeakerSpain (props) {
  return (
    <>
      <h2>Spain Bell Beaker culture (<Years culture={props} />)</h2>
      <br />
      {bellBeakerSpainPreview}
      <br />
      <br />
      <img src={image01} alt='Spain Bell Beaker culture' />
      <legend> Vessel from Ciempozuelos (Spain), beginning of the second millennium BC</legend>
      <br />
      The identification of these distinctive beakers is significant because it is the first archaeological evidence of wide-spread cultural diffusion—the spread of ideas. Archaeologists have theorized that they used these beakers for rituals, smelting, drinking, to store food, or as funerary urns. Artifacts of these people also include gold, bronze, and copper metalwork, stone wrist-guards, daggers, and v-perforated buttons.
      <br />
      <br />
      The Bell Beakers had a rich spiritual life. They not only adopted the Great Goddess, the most common religion in prehistoric Europe, but have armed her and had her prepared to protect them with a new sword or dagger.
      <br />
      <br />
      <img src={image02} alt='Spain Bell Beaker culture' />
      <legend> Ceramic dish from the necropolis of Ciempozuelos in Spain (2200-1800 BC)</legend>
      <br />
      The first Bell Beaker site in Spain was found 50 kilometers from Valencina de la Concepción includes earthwork enclosure of complex, concentric rings with a round hole in the center 19 meters wide. The purpose of this enclosure is unknown, though it may have served a ritual or religious purpose.
      Bell Beaker burial sites typically included caves in places like the Prades Mountains and were used for a single or mass burial. Grave goods include flint blades, stone axes, potsherds, beads, and jewelry made of gold or amber, or shells. The Bell Beakers used the caves both for ritualistic and cultic activity as well as funerary purposes. The deads were sometimes joined by their beloved pets. The ceremonies conducted on the site included fasts and ritualistic tools. They aimed to connect the dead with their ancestors and the sun through the offerings.
      <br />
      <br />
      Of the 23 males of the Beaker culture found in Spain, 22 carried the Y-chromosome haplogroup R1b1a1a commonly found in Europe, Anatolia, the Caucasus, and its subbranches. One male carried the CT Y haplogroup, found almost exclusively in Africa, which highlights the contribution of Africans to the Western European biodiversity since ancient times. The mitochondrial haplogroups of the 23 males and 15 females included a wide variety of H, J, K, T, U, V, and X, which supports an extensive mixing of the males with females throughout the continent. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup I dates to the Paleolithic Iran/Caucuses area and likely arrived with steppe peoples into Europe. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup K is common in Europe today and was recently derived from U, which was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup T is probably Middle Eastern in origin and found today throughout Central Asia and in low frequencies in Europe. Haplogroup X and has a very ancient ancestry and is now common in West Asia.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/37lY2UX'>"The Archaeology of Bronze Age Iberia: Argaric Societies"</a></strong>
      <br />
      <br />
      <iframe title='The Archaeology of Bronze Age Iberia: Argaric Societies' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=1138821330&asins=1138821330&linkId=8eb1ab510fb5fb7e5bfd7b693ad365ae&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default bellBeakerSpain
