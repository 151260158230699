import React, { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react'
import '../../styles/_timeline.scss'

import { useInterval, usePrevious, useKeyPress, useQuery, useWindowSize } from '../../utils/hooks'
import { PreviewContext } from '../preview-page'
import AppContext from '../app-context'
import isEmpty from 'lodash.isempty'
import { isNumber } from '../../utils'

const timelineBoundaries = {}
function getTimelineBoundaries (culturesConf) {
  if (!timelineBoundaries.timelineEnd && !isEmpty(culturesConf)) {
    timelineBoundaries.timelineStart = Math.min(...culturesConf.map(({ From }) => From)) - 100
    timelineBoundaries.timelineEnd = Math.max(...culturesConf.map(({ To }) => To))
  }
  return timelineBoundaries
}

const defaultPauseTime = 1300
const intervalTime = 20

let timeoutId
let timeoutIdPause

const defaultObject = { era: { } }

function TimelineSlider ({ culturesConf, setFilterdCultures, localTime, setIsEndTime }) {
  const query = useQuery()
  const mounted = useRef(false)

  const {
    cultures,
    auto,
    setAuto,
    newCulture,
    setNewCulture,
    setLocalTime
  } = useContext(PreviewContext)
  const { geneticTestConfig } = useContext(AppContext)
  const thumb = useRef(null)
  const [pauseTime, setPauseTime] = useState(query.get('p') ?? defaultPauseTime)
  const [previousTimeJump, setPreviousTimeJump] = useState()
  const [nextTimeJump, setNextTimeJump] = useState()
  const [playSpeed, setPlaySpeed] = useState(8)
  const [checkCultures, setCheckCultures] = useState(true)
  const [timeJumps, setTimeJumps] = useState([])
  const [wait, setWait] = useState(false)
  const prevCultures = usePrevious(cultures)
  const idxsCurrentCultures = useMemo(() => Array.isArray(cultures) ? cultures.map(c => c.idx) : [], [cultures])

  useKeyPress(' ', () => setAuto(!auto)) // on space start or stop

  let { timelineStart, timelineEnd } = getTimelineBoundaries(culturesConf)
  const {
    startAge = null,
    endAge = null
  } = (geneticTestConfig || defaultObject).era

  if (startAge != null && startAge !== '') timelineStart = startAge
  if (endAge != null && endAge !== '') timelineEnd = endAge

  const showThumbLabel = !auto && localTime !== timelineStart && localTime !== timelineEnd

  const checkFireCultureChange = useCallback((newTime) => {
    const activeCulturesIdxs = culturesConf.map((c, i) => {
      c.isActive = c.From <= newTime && newTime <= c.To
      if (c.isActive) return c.idx
      return null
    }).filter(v => v >= 0)
    activeCulturesIdxs.sort()
    const currentActiveCultIdx = (cultures || []).filter(c => c.isActive).map(c => c.idx)
    if (currentActiveCultIdx) currentActiveCultIdx.sort()
    const newFilteredCultures = culturesConf.filter(culture => culture.From <= newTime)
    const newCultFiltIdxs = newFilteredCultures.map(c => c.idx)
    newCultFiltIdxs.sort()

    if (newCultFiltIdxs.join('') !== idxsCurrentCultures?.join('') || activeCulturesIdxs.join('') !== currentActiveCultIdx?.join('')) {
      setFilterdCultures(newFilteredCultures)
    }
  }, [culturesConf, idxsCurrentCultures, setFilterdCultures, cultures])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (!timeJumps || timeJumps.length === 0) return
    if (previousTimeJump <= localTime && localTime < nextTimeJump) return
    let prevTimeJump = false
    for (const timeJump of timeJumps) {
      if (prevTimeJump && localTime < timeJump) {
        const nextTimeJumpIdx = timeJumps.indexOf(prevTimeJump) + 1
        const nextTimeJump = timeJumps[nextTimeJumpIdx]
        setPreviousTimeJump(prevTimeJump)
        setNextTimeJump(nextTimeJump)
        break
      }
      prevTimeJump = timeJump
    }
  }, [localTime, timeJumps, nextTimeJump, previousTimeJump])

  useEffect(() => {
    if (timeJumps.length > 0) {
      const range = Math.abs(nextTimeJump - previousTimeJump)
      setPlaySpeed(range / (pauseTime / intervalTime))
    }
  }, [localTime, nextTimeJump, previousTimeJump, timeJumps, pauseTime])

  useInterval(() => {
    const toAdd = playSpeed
    if (auto && localTime < timelineEnd && timeJumps.length > 0 && !wait) {
      let newTime = localTime + toAdd
      let idx = -1
      if (newTime >= nextTimeJump) {
        idx = timeJumps.indexOf(nextTimeJump)
        newTime = nextTimeJump
      }
      if (idx !== -1) {
        // if (idx === timeJumps.length - 2) setPlaySpeed(12)
        setWait(true)
        setCheckCultures(true)
        setTimeout(() => {
          if (mounted.current) setWait(false)
        }, pauseTime)
      } else {
        setCheckCultures(false)
      }
      setLocalTime(newTime)
    }
  }, intervalTime)

  useEffect(() => {
    if (culturesConf && isNumber(timelineStart) && isNumber(timelineEnd)) {
      culturesConf.sort((a, b) => a.From - b.From)
      let times = culturesConf.map(c => c.From)
      times = [timelineStart, ...times, timelineEnd]
      times = [...new Set(times)]
      setTimeJumps(times)
    }
  }, [culturesConf, timelineEnd, timelineStart])

  useEffect(() => {
    if ((localTime == null || localTime === false || isNaN(localTime)) && isNumber(timelineStart)) {
      setLocalTime(timelineStart)
    }
  }, [setLocalTime, timelineStart, localTime])

  useEffect(() => {
    if (checkCultures) checkFireCultureChange(localTime)
    if (isNumber(localTime) && isNumber(timelineEnd) && localTime === timelineEnd) {
      setIsEndTime(true)
    }
  }, [localTime, checkCultures, timelineEnd])

  // useEffect(() => {
  //   if (prevNewCulture !== newCulture && auto) {
  //     clearTimeout(timeoutIdPause)
  //     const currentPlayspeed = playSpeed
  //     timeoutIdPause = setTimeout(() => setPlaySpeed(currentPlayspeed), 1300)
  //     // console.log('setting 0')
  //     setPlaySpeed(0)
  //   }
  // }, [newCulture, auto, prevNewCulture])

  useEffect(() => {
    const idxsPreveCultures = prevCultures?.map(c => c.idx)
    if (idxsCurrentCultures) idxsCurrentCultures.sort()
    if (idxsPreveCultures) idxsPreveCultures.sort()

    if (idxsCurrentCultures?.join('') !== idxsPreveCultures?.join('')) {
      const cultureAdded = cultures?.filter(e => !idxsPreveCultures?.includes(e.idx))[0]
      const cultureRemoved = prevCultures?.filter(e => !idxsCurrentCultures?.includes(e.idx))[0]
      const newCul = cultureAdded || cultureRemoved
      setNewCulture(newCul)
    }
  }, [cultures, prevCultures])

  useEffect(() => {
    return () => {
      // console.log('clearing timeouts slider')
      [timeoutId, timeoutIdPause].forEach(tId => clearTimeout(tId))
    }
  }, [])

  const playBtnFn = (pauseTime = defaultPauseTime) => {
    clearTimeout(timeoutIdPause)
    if (!auto) setAuto(true)
    setPauseTime(pauseTime)
    // setPlaySpeed(playSpeed)
  }

  // console.log('localTime', localTime, timelineStart, timelineEnd)

  return (
    <div className='timeline'>
      <div className='timeline-bars'>{isNumber(timelineStart) && isNumber(timelineEnd) && <TimelineBars timelineStart={timelineStart} timelineEnd={timelineEnd} />}</div>
      {localTime != null && isNumber(localTime) && isNumber(timelineStart) && isNumber(timelineEnd) &&
        <input
          ref={thumb}
          name='timeline'
          className='timeline-slider'
          type='range'
          min={timelineStart}
          max={timelineEnd}
          step={1}
          value={localTime}
          onChange={(e) => {
            setCheckCultures(false)
            clearTimeout(timeoutId)
            setAuto(false)
            const val = +e.target.value
            setLocalTime(val)
            timeoutId = setTimeout(() => {
              if (mounted.current) setCheckCultures(true)
            }, 500)
          }}
        />}

      {showThumbLabel && (
        <span
          className='timeline-thumb-label'
          style={{
            left:
              Number(((localTime - timelineStart) * 100) / (timelineEnd - timelineStart)) + '%'
          }}
        >
          {Math.round(localTime)}
        </span>
      )}
      <span className='timeline-date'><TimelineMsg culture={newCulture} /></span>

      <div className='timeline-pauseplay'>
        <i className={auto ? 'timeline-pause' : 'timeline-play'} onClick={() => auto ? setAuto(false) : playBtnFn()} />
        <i className='timeline-playfast icofont-before icofont-double-right icofont-nm' onClick={() => playBtnFn(defaultPauseTime / 2)} />
      </div>

    </div>
  )
}

function TimelineMsg (props) {
  const { culture } = props
  if (!culture) return null
  const { From, Culture, idx } = culture
  return (
    <p key={'TimelineMsg-' + idx} className='timeline-date-fade'>
      {Culture} <span className='timeline-date-appear'>appears at</span>{' '}
      {Math.abs(From)} {From <= 0 ? 'BC' : 'AC'}
    </p>
  )
}

function TimelineBars (props) {
  const { timelineStart, timelineEnd } = props
  const { width } = useWindowSize()
  const arr = []
  // const numbers = parseInt((timelineStart - timelineEnd) / 50)
  const numbers = parseInt(width / 50)

  for (let i = 0; i < numbers; i++) {
    arr.push(
      <span key={i} className='timeline-bar'>
        {i === 0 && <span className='timeline-bar-label'>{Math.abs(timelineStart)} {timelineStart < 0 ? 'BC' : 'AC'}</span>}
        {i === numbers - 1 && <span className='timeline-bar-label timeline-bar-label-end'>{Math.abs(timelineEnd)} {timelineEnd <= 0 ? 'BC' : 'AC'}</span>}
      </span>
    )
  }
  return arr
}

export default TimelineSlider
