import React, { useContext } from 'react'
import AppContext from '../app-context'

function ReferencedTests () {
  const { geneticTestConfig } = useContext(AppContext)

  const {
    urls = '',
    texts = ''
  } = (geneticTestConfig || { referencedTests: {} }).referencedTests

  const urlLinks = urls.split('\n').map(u => u.trim()).filter(u => u != null && u.length > 0)
  const testTexts = texts.split('\n').map(t => t.trim()).filter(t => t != null && t.length > 0)

  return (
    <div>
      {urlLinks.map((u, idx) => <a className='display-block t-c t-white p30 t-25' style={{ backgroundColor: idx % 2 === 0 ? '#4264aa' : '#263b63' }} href={u} key={u}>{testTexts[idx]}</a>)}
    </div>
  )
}

export default ReferencedTests
