import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/assyrian_text_01.jpg'
import image02 from '../../assets/images/cultures/assyrian_text_02.jpg'
import image03 from '../../assets/images/cultures/assyrian_text_03.jpg'
import image04 from '../../assets/images/cultures/assyrian_text_04.jpg'
import image05 from '../../assets/images/cultures/assyrian_text_05.jpg'
import image06 from '../../assets/images/cultures/assyrian_text_06.png'
import image07 from '../../assets/images/cultures/assyrian_text_07.jpg'

export const assyrianPreview = 'The Assyrians were a Semitic people who established the Assyrian Empire and its capital Ashur between 2025 and 608 BC in today’s Iraq. At its peak, the Assyrian empire ruled over the ancient Mesopotamian religion as far north as the Caucasus Mountains, as far east as the Zagros Mountains, as far south as the Arabian Desert and, as far west as Cyprus and Egypt. The Assyrians were literate people who spoke and wrote Akkadian before Aramaic became more popular. The city of Ashur was named after the god Ashur, the head of the Assyrian pantheon, who protected the city.'

function assyrian (props) {
  return (
    <>
      <h2>The Assyrian culture (<Years culture={props} />)</h2>
      <br />
      {assyrianPreview}
      <br />
      <br />
      <img src={image01} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      As other Mesopotamians, the Assyrians were polytheistic with a pantheon of gods headed by the national god Assur and his family, Ishtar, Adad, Sin, Ninurta, Nergal, and Ninlil that they adopted from the Sumerians and Akkadians. The Assyrians were so influenced by these cultures that they also adopted their language (Akkadian and Sumerian) and architecture styles as they attempted to develop their unique style. Their world contained spirits, deities, and demons like Lamassu that surrounded men and animals and determined their fate. The Assyrians had a strong influence on the Hebrews and the Bible’s authors who incorporated the Assyrian myths and beliefs into their masterpiece, like the Epic of Creation.
      <br />
      <br />
      <img src={image02} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      The tale of Moses put in a basket, for instance, parallels the legend of how Sargon was born, put in a basket, found, and rose to fame.
      <br />
      <br />
      <img src={image03} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      The most famous Assyro-Babylonian hero is Gilgamesh, whose story is one of the most ancient tales and was recorded during the 7th century BC.
      <br />
      <br />
      Gilgamesh became the role model for the Assyrian kings who considered themselves the most powerful heroes and did not miss any opportunity to glorify themselves and their doings, justified or not, by erecting steles and rewriting their history books. They also lived in constant fear of rebels and conspiracies and did not take violations of oaths and contracts lightly. The Israelites could attest to that first hand.
      <br />
      <br />
      <img src={image04} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      During the early eighth century BC, the Assyrians began to collect taxes from the Kingdom of Israel. After a few years, Tiglath-Pileser III began to deport some of the Israelite cities to Assyria. The Assyrians did that not only to punish Israel but also to acquire craftsmen, artists, engineers, and other skilled experts to develop Assyria. A few years later, the Assyrians arrived in the region again to intervene in a civil war between Israel and Judea. The Judean king was a vassal of Assyria who paid his taxes on time, and when attacked by the Israelite and Damascus kings, he evoked the terms of his oath and asked for help. The Assyrians used this opportunity to weaken Damascus even more, and the Israelite king was killed.
      <br />
      <br />
      <img src={image05} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      A few years later, Hoshea Ben Elle, the new King of Israel, planned a rebellion against the Assyrians, in violation of his sacred oath to Assyria. King Shalmaneser V put Samaria under siege, and in the third year at 722 BC, he captured the city. Shalmaneser deported a significant number of the population, which brought the end of the Israelite Kingdom. This deportation is known as the exile of the Ten Lost Tribes. Since then, endless efforts were made to identify the Lost Tribes and their whereabouts and end to their exile (Galut). The messianic view espouses that the reunition of the Israelite Tribes would bring the Messiah (savior). This meant, of course, that almost any population in the world has either been associated or claimed to be related to the Lost Tribes. None of those links can be tested or confirmed. Ancient DNA is the only test that can be used to evaluate the extent of genetic similarity between populations and the ancient Israelites. Such tests were not carried as of yet.
      <br />
      <br />
      <img src={image06} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      The end of the Assyrian Empire itself came due to internal and external factors. After being weakened by internal struggles for the throne, the Empire was sacked by the Babylonian. The major cities of Ashur and Ninve were utterly destroyed in 614 and 612 BC, respectively. Assyria was ruled from Babylon until Babylon was destroyed by the Persians (539 BC) and later Alexander the Great (330 BC). This was a period of revival as the Assyrians joined the Persian army ranks, and the Persians adopted Aramaic for 200 years. The Assyrians survived till modern day though it is unclear to what extent they preserved the Assyrian genetic signature. This test was designed to answer such questions and more.
      <br />
      <br />
      <img src={image07} alt='The Assyrian culture' />
      <legend> A relief of the god Ashur (9th-8th century BC) (source: Wikipeida)</legend>
      <br />
      The two men and one woman included in this test date to 1700 BC. They were found at Kaman-Kalehöyük with grave goods that include seals and ceramics, in support of the international trade system managed by the Assyrian merchants as evidenced at the nearby site of Kültepe/Kanesh. They were found in a public building or a shopping area and may have died in a battle. The two men belonged to haplogroup J, which originated in the Middle and Near East and was common from the Neolithic till modern days. It is a minor lineage in most of Europe today but a major one in Greece, Italy, Arabia, and the Levant. The maternal haplogroups are H, J, and U. Haplogroup H is commonly found in Europe today and is known to have Middle Eastern origins. It has also been found in Mesolithic hunter-gatherers, and exist in Europe before the arrival of agriculture. It is common in Europe today is of Middle Eastern origin, having been tracked out of Franco-Cantabrian region, and was around in Europe during the Paleolithic. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup U, was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/3lZ46GY'>"The Assyrian Prophecy"</a></strong>
      <br />
      <br />
      <iframe title='The Assyrian Prophecy' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0578508087&asins=0578508087&linkId=d83aa2ae32f61f4606f14e4ee6f70257&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>)
}
export default assyrian
