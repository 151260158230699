import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/mycenaean_text_01.jpg'
import image02 from '../../assets/images/cultures/mycenaean_text_02.jpg'
import image03 from '../../assets/images/cultures/mycenaean_text_03.jpg'
import image04 from '../../assets/images/cultures/mycenaean_text_04.png'
import image05 from '../../assets/images/cultures/mycenaean_text_05.jpg'
import image06 from '../../assets/images/cultures/mycenaean_text_06.jpg'

export const mycenaeanPreview = 'The fall of the Minoans in the mid-second millennium cleared the Greek stage for the Mycenaeans, who emerged around 1700 BC and became the first advanced civilization in mainland Greece until 1050 BC. Combining native and cultural influences from Crete, the Mycenaeans became the dominant force in the Aegean.'

function mycenaean (props) {
  return (
    <>
      <h2>Mycenaean culture (<Years culture={props} />)</h2>
      <br />
      {mycenaeanPreview}
      <br />
      <br />
      <img src={image01} alt='The Mycenaean culture' />
      <legend> Mycanae’s Gate of the Lions can be found in the man entrance to the fortified citadel. The confronting lionesses posing on both sides of a Minoan pillar (source: Wikipedia)</legend>
      <br />
      The Mycenaeans were named after the type-site of that civilization. The Mycenaeans settled all of mainland Greece up to Thessaly and Aegean islands. They reached Western Cyprus, Italy, and Anatolia and, like the Minoans, traded with Egypt and Ugarit, the two superpowers of the eastern Meditteranean.
      <br />
      <br />
      <img src={image02} alt='The Mycenaean culture' />
      <legend> A Bronze Reconstruction of a Mycenaean ship (source: Wikimedia Commons)</legend>
      <br />
      Their origins are in Proto Indo-Europeans groups who mingled in the Eurasian steppe north of the Black and Caspian seas and the Balkans. These migrants combined their knowledge of metallurgy, maritime trade, and literacy with the customs and traditions of the local populations and the remainders of the lost Atlantic (Minoan) civilization.
      <br />
      <br />
      The Mycenaeans were more militaristic than their predecessors. They fortified their cites with tall and round walls. They were very skilled craftsmen and artisans known for their decorated pottery, clay figurines, fighting gear, and complex economic system. Homer (who wrote 300 years after the decline of the Mycenaeans) ascribed the Mycenae city with Agamemnon, the mythological Greek leader who led the attack on Troy after Paris kidnapped king Menelaus’s wife.
      <br />
      <br />
      <img src={image03} alt='The Mycenaean culture' />
      <legend> The so-called Mask of Agamemnon which was discovered by Heinrich Schliemann in 1876 at Mycenae, now believed to pre-date the legendary Trojan War by 300 years (source: Wikipedia)</legend>
      <br />
      Their origins are in Proto Indo-Europeans groups who mingled in the Eurasian steppe north of the Black and Caspian seas and the Balkans. These migrants combined their knowledge of metallurgy, maritime trade, and literacy with the customs and traditions of the local populations and the remainders of the lost Atlantic (Minoan) civilization.
      <br />
      <br />
      The Mycenaeans were more militaristic than their predecessors. They fortified their cites with tall and round walls. They were very skilled craftsmen and artisans known for their decorated pottery, clay figurines, fighting gear, and complex economic system. Homer (who wrote 300 years after the decline of the Mycenaeans) ascribed the Mycenae city with Agamemnon, the mythological Greek leader who led the attack on Troy after Paris kidnapped king Menelaus’s wife.
      <br />
      <br />
      <img src={image04} alt='The Mycenaean culture' />
      <legend> Electra and Orestes, from an 1897 Stories from the Greek Tragedians, by Alfred Church (source: Wikipedia)</legend>
      <br />
      Several psychological conditions were inspired by this story:  An “Achilles heel” is a minor weakness, which can lead to one’s downfall in spite of their significant strength, the “Electra complex,” where a girl competes with her mother for the affections of her father, is named after Electra; and the “Cassandra syndrome,” where one’s valid warnings are disbelieved by others, is named after Cassandra’s cursed gift from god Apollo. Cassandra’s prophecies that were ignored foresaw the sparkling of the Trojan War, the destruction of Troy, and even the death of Agamemnon and herself.
      <br />
      <br />
      <img src={image05} alt='The Mycenaean culture' />
      <legend> Cassandra in front of the burning city of Troy in Cassandra by Evelyn De Morgan (1898, London) (source: Wikipedia)</legend>
      <br />
      The Mycenaeans have expanded the pantheon inherited from the Minoans and added many Greek deities including Zeus, Poseidon, Ares, Dionysos, and Hermes and even a larger number of Goddesses including Artemis and Hera all of whom had feelings and weaknesses and needed to be pleased with gifts and sacrifice, including human sacrifice. Cultic shrines can be found in many places like Abae Delphi, Delos, and Lerna.
      <br />
      <br />
      <img src={image06} alt='The Mycenaean culture' />
      <legend> Fresco depicting a goddess or priestess in Mycenae, 1250–1180 BC (Source: Wikipedia)</legend>
      The Mycenaeans began declining at 1250 BC when a serious of disasters hit them. The survivors moved to neighboring islands, and by 1050 BC it was no longer possible to detect the unique Mycenaeans culture
      <br />
      Four individuals are included in this test. Two females and one male were discovered in a Mycenaean tomb at the site of Ayia Kyriaki, near the town of Salamina. The grave goods included a large number of clay, jewelry, and bronze objects. The fourth female was found in a cemetery of the Peristeria citadel in Trifylia, 8 km northeast of Kyparissia. The wealth found in the tomb evidenced the importance of the site, which was fortified and served the rulers. The grave goods include a golden crown, gold necklaces, a sword decorated with silver, obsidian arrowheads and flint, and clay vases.
      <br />
      <br />
      The male belonged to haplogroup J, which originated in the Middle and Near East and was common from the Neolithic till modern days. It is a minor lineage in Europe today but a major one in Greece, Italy, Arabia, and the Levant. The three women belonged to mitochondrial haplogroup H and X. Haplogroup H is commonly found in Europe today and is known to have Middle Eastern origins. It has also been found in Mesolithic hunter-gatherers and exists in Europe before the arrival of agriculture. It is common in Europe today, is of Middle Eastern origin, having been tracked out of Franco-Cantabrian region, and was around in Europe during the Paleolithic. Haplogroup X is centered in the East Mediterranean but also represented in America. Haplogroup X is believed by many to have been the signature of the Phoenicians, who followed in the maritime paths of the Minoans and Mycenaeans. One individual belonged to mitochondrial haplogroup U, the most common haplogroup among European hunter-gatherers of the Paleolithic before the domination of H after the spread of agriculture. This last (H) was the mitochondrial haplogroup of the remaining individual. The only male in this sample belonged to the Y-chromosomal haplogroup J.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/2H3ZVeq'>"The Mycenaeans"</a></strong>
      <br />
      <br />
      <iframe title='The Mycenaeans' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0892368675&asins=0892368675&linkId=d59f0c6daacb626a4e25606d7c4063c0&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default mycenaean
