import React, { createContext } from 'react'

export const AppContext = createContext()

export function AppContextProvider (props) {
  const context = {
    geneticTestConfig: props.geneticTestConfig,
    isPreview: props.isPreview
  }

  return (
    <AppContext.Provider value={context}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContext
