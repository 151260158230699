import React, { useContext } from 'react'
import AppContext from '../app-context'

function About () {
  const { geneticTestConfig } = useContext(AppContext)

  const {
    icon,
    description
  } = (geneticTestConfig || { hp: { about: {} } }).hp.about

  return (
    <div className='about-wrap'>
      <div className='content'>
        <div className='icon'>
          <img src={icon} alt='about-icon' />
        </div>
        <div className='text' dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  )
}

export default About
