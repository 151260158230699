import React from 'react'
import { Link, useLocation } from 'react-router-dom'
// import twitter from '../assets/images/social/twitter.png'
// import facebook from '../assets/images/social/facebook.png'
// import google from '../assets/images/social/google.png'
// import plus from '../assets/images/social/plus.png'

function Footer () {
  const location = useLocation()
  return (
    <div className='footer-wrap'>
      {/* <div className="content">
          <div className="footer-col">
              <div className="title">About us</div>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div>
          <div className="footer-col">
                <div className="title">Quick Link</div>
                <ul>
                    <li><a href="">Home</a></li>
                    <li><a href="">Services</a></li>
                    <li><a href="">Apointment</a></li>
                </ul>
          </div>
          <div className="footer-col">
                <div className="title">Pages</div>
                <ul>
                    <li><a href="">FAQ</a></li>
                    <li><a href="">Genepools</a></li>
                    <li><a href="">Cultures</a></li>
                </ul>
          </div>
          <div className="footer-col social-icon">
                <div className="title">Share this icon</div>
                <ul>
                    <li><a href=""><img src={twitter} /></a></li>
                    <li><a href=""><img src={facebook} /></a></li>
                    <li><a href=""><img src={google} /></a></li>
                    <li><a href=""><img src={plus} /></a></li>
                </ul>
          </div>
      </div> */}
      <div className='content'>
        <div className='footer-col'>
          <div className='title'>Pages</div>
          <ul>
            <li><Link to={{ pathname: '/faq', search: location.search }}>FAQ</Link></li>
            <li><Link to={{ pathname: '/gene-pools', search: location.search }}>Gene Pools</Link></li>
            <li><Link to={{ pathname: '/cultures', search: location.search }}>Cultures</Link></li>
            <li><Link to={{ pathname: '/icons-copyrights', search: location.search }}>Icons copyrights</Link></li>
          </ul>
        </div>
      </div>
      <div className='copyright'>
        <span>Copyright &copy; {new Date().getFullYear()} <a href='www.geneplaza.com' target='_blank'>GenePlaza.</a> All rights reserved</span>
      </div>
    </div>
  )
}

export default Footer
