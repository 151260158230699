import React, { useContext, useEffect, useMemo, useState } from 'react'
import AppContext from '../app-context'
import { separateAndOrderBeginnerAndAdvancedCulturesAndGenePools } from '../../utils'

const setAppId = (appLink, setter) => {
  const appIdMatchRegex = /app-store\/(\d+)\//i
  const appId = (appLink.match(appIdMatchRegex) ?? [])[1]
  if (appId) setter(appId)
}

const getAppInfo = async (appId) => {
  // console.log('setAppInfo', appId)
  try {
    const appLink = `https://www.geneplaza.com/api/apps/${appId}`
    const repsonse = await fetch(appLink)
    const appInfo = await repsonse.json()
    if (appInfo && appInfo.data.attributes) {
      // console.log(appId, appInfo.data.attributes)
      return appInfo.data.attributes
    }
  } catch (error) {
    console.warn(error)
  }
}

function Package (props) {
  const [beginnerAppId, setBeginnerAppId] = useState(null)
  const [advancedAppId, setAdvancedAppId] = useState(null)
  const [beginnerAppInfo, setBeginnerAppInfo] = useState(null)
  const [advancedAppInfo, setAdvancedAppInfo] = useState(null)
  const {
    genePoolsConf,
    culturesConf
  } = props
  const { geneticTestConfig } = useContext(AppContext)

  const {
    bgnr: beginner = {},
    advd: advanced = {},
    bgImg
  } = (geneticTestConfig || { hp: { package: {} } }).hp.package

  const {
    beginnerCultures,
    beginnerGenePools,
    advancedCultures,
    advancedGenepools
  } = useMemo(() => separateAndOrderBeginnerAndAdvancedCulturesAndGenePools(culturesConf, genePoolsConf), [culturesConf, genePoolsConf])

  useEffect(() => {
    if (beginner && typeof beginner.appLink === 'string') setAppId(beginner.appLink, setBeginnerAppId)
    if (advanced && typeof advanced.appLink === 'string') setAppId(advanced.appLink, setAdvancedAppId)
  }, [beginner, advanced])

  useEffect(() => {
    let isCurrentRenderLoop = true
    if (beginnerAppId && beginnerAppInfo == null) {
      getAppInfo(beginnerAppId).then(appInfo => {
        if (!isCurrentRenderLoop) return
        setBeginnerAppInfo(appInfo)
      })
    }
    if (advancedAppId && advancedAppInfo == null) {
      getAppInfo(advancedAppId).then(appInfo => {
        if (!isCurrentRenderLoop) return
        setAdvancedAppInfo(appInfo)
      })
    }
    return () => {
      isCurrentRenderLoop = false
    }
  }, [beginnerAppId, advancedAppId, beginnerAppInfo, advancedAppInfo])

  // TODO split each col in a separate component, to avoid duplication
  return (
    <div className='package-wrap' id='test' style={{ backgroundImage: `url(${bgImg})` }}>
      <div className='content clusterColor'>
        {/* BEGINNER */}
        <div className='package-col col-50'>
          <div className='title'>{beginner.title}</div>
          <div className='info' dangerouslySetInnerHTML={{ __html: beginner.info }} />
          <div className='genepool'>
            <div className='genepool-title'>{beginnerGenePools.length} Genepools</div>
            <ul>
              {beginnerGenePools.map(gp =>
                <li key={'gp-beginner-' + gp.name}>
                  <span style={{ backgroundColor: gp.color }} />
                  <span>{gp.name} ({gp.region})</span>
                </li>
              )}
            </ul>
          </div>
          <div className='genepool'>
            <div className='genepool-title'>{beginnerCultures.length} Ancient cultures</div>
            <ul>
              {beginnerCultures.map(c =>
                <li key={'c-beginner-' + c.Culture}>
                  <span style={{ backgroundColor: c.color }} />
                  <span>{c.Culture} ({c.Country})</span>
                </li>
              )}
            </ul>
          </div>
          <div className='price'>
            {beginnerAppInfo && +beginnerAppInfo['display-price'] > 0
              ? (
                <>
                  <strong>Only at ${+beginnerAppInfo.price / 100}*</strong>
                  <span>Normal Price ${+beginnerAppInfo['display-price'] / 100}*</span>
                </>
                )
              : <span style={{ textDecoration: 'none' }}>Price ${beginnerAppInfo && beginnerAppInfo.price ? +beginnerAppInfo.price / 100 : 'todo'}</span>}
            {/* <span style={{ color: 'red', textDecoration: 'none'}}>Special DNA Day price</span> */}
            <strong>Available in GenePlaza</strong>
          </div>
          <div className='price-btn'>
            <a href={beginner.appLink} target='_blank' rel='noreferrer noopener'>Buy the test</a>
          </div>
        </div>
        {/* ADVANCED */}
        <div className='package-col col-50'>
          <div className='title'>{advanced.title}</div>
          <div className='info' dangerouslySetInnerHTML={{ __html: advanced.info }} />
          <div className='genepool'>
            <div className='genepool-title'>{advancedGenepools.length} Genepools</div>
            <ul>
              {advancedGenepools.map(gp =>
                <li key={'gp-advanced-' + gp.name}>
                  <span style={{ backgroundColor: gp.color }} />
                  <span>{gp.name} ({gp.region})</span>
                </li>
              )}
            </ul>
          </div>
          <div className='genepool'>
            <div className='genepool-title'>{advancedCultures.length} Ancient cultures</div>
            <ul>
              {advancedCultures.map(c =>
                <li key={'c-advanced-' + c.Culture}>
                  <span style={{ backgroundColor: typeof c.color === 'string' ? c.color.toLowerCase() : c.color }} />
                  <span>{c.Culture} ({c.Country})</span>
                </li>
              )}
            </ul>
          </div>
          <div className='price'>
            {advancedAppInfo && +advancedAppInfo['display-price'] > 0
              ? (
                <>
                  <strong>Only at ${+advancedAppInfo.price / 100}*</strong>
                  <span>Normal Price ${+advancedAppInfo['display-price'] / 100}*</span>
                </>
                )
              : <span style={{ textDecoration: 'none' }}>Price ${advancedAppInfo && advancedAppInfo.price ? +advancedAppInfo.price / 100 : 'todo'}</span>}
            {/* <span style={{ color: 'red', textDecoration: 'none'}}>Special DNA Day price</span> */}
            {/* {<span style={{ color: 'red', textDecoration: 'none' }}>Special Black Friday price</span>} */}
            <strong>Available in GenePlaza</strong>
          </div>
          <div className='price-btn'>
            <a href={advanced.appLink} target='_blank' rel='noreferrer noopener'>Buy the test</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Package
