import ainGhazalStory, { ainGhazalPreview } from './ain_ghazal.js'
import assyrianStory, { assyrianPreview } from './assyrian.js'
import bellBeakerEnglandEBAStory, { bellBeakerEnglandEBAPreview } from './bell_beaker_eba_england.js'
import bellBeakerScotlandEBAStory, { bellBeakerScotlandEBAPreview } from './bell_beaker_eba_scotland.js'
import bellBeakerWalesEBAStory, { bellBeakerWalesEBAPreview } from './bell_beaker_eba_wales.js'
import bellBeakerScotlandLBAStory, { bellBeakerScotlandLBAPreview } from './bell_beaker_lba_scotland.js'
import bellBeakerEnglandMBAStory, { bellBeakerEnglandMBAPreview } from './bell_beaker_mba_england.js'
import bellBeakerWalesMBAStory, { bellBeakerWalesMBAPreview } from './bell_beaker_mba_wales.js'
import bellBeakerSpainStory, { bellBeakerSpainPreview } from './bell_beaker_spain.js'
import canaanitesStory, { canaanitesPreview } from './canaanite.js'
import chongoniRockArtistsStory, { chongoniRockArtistsPreview } from './chongoni.js'
import cordedWareGermanyStory, { cordedWareGermanyPreview } from './corded_ware_germany.js'
import cuncaichaHighlandStory, { cuncaichaHighlandPreview } from './cuncaicha.js'
import deerStoneStory, { deerStonePreview } from './deer_stone.js'
import dongSonStory, { dongSonPreview } from './dong_son.js'
import hittiteStory, { hittitePreview } from './hittite.js'
import jomonStory, { jomonPreview } from './jomon.js'
import lapitaStory, { lapitaPreview } from './lapita.js'
import minoanStory, { minoanPreview } from './minoan.js'
import mycenaeanStory, { mycenaeanPreview } from './mycenaean.js'
import preDorsetStory, { preDorsetPreview } from './pre_dorset.js'
import protocogotasStory, { protocogotasPreview } from './protocogotas.js'
import sakaStory, { sakaPreview } from './saka.js'
import sanNicolasIslandStory, { sanNicolasIslandPreview } from './san_nicolas.js'
import saqqaqStory, { saqqaqPreview } from './saqqaq.js'
import southWesternIberianBronzeStory, { southWesternIberianBronzePreview } from './south-western_iberian.js'
import yamnayaStory, { yamnayaPreview } from './yamnaya.js'

export const previews = {
  yamnayaStory: yamnayaPreview,
  southWesternIberianBronzeStory: southWesternIberianBronzePreview,
  saqqaqStory: saqqaqPreview,
  sanNicolasIslandStory: sanNicolasIslandPreview,
  sakaStory: sakaPreview,
  protocogotasStory: protocogotasPreview,
  preDorsetStory: preDorsetPreview,
  mycenaeanStory: mycenaeanPreview,
  minoanStory: minoanPreview,
  lapitaStory: lapitaPreview,
  jomonStory: jomonPreview,
  ainGhazalStory: ainGhazalPreview,
  assyrianStory: assyrianPreview,
  bellBeakerEnglandEBAStory: bellBeakerEnglandEBAPreview,
  bellBeakerScotlandEBAStory: bellBeakerScotlandEBAPreview,
  bellBeakerWalesEBAStory: bellBeakerWalesEBAPreview,
  bellBeakerScotlandLBAStory: bellBeakerScotlandLBAPreview,
  bellBeakerEnglandMBAStory: bellBeakerEnglandMBAPreview,
  bellBeakerWalesMBAStory: bellBeakerWalesMBAPreview,
  bellBeakerSpainStory: bellBeakerSpainPreview,
  canaanitesStory: canaanitesPreview,
  chongoniRockArtistsStory: chongoniRockArtistsPreview,
  cordedWareGermanyStory: cordedWareGermanyPreview,
  cuncaichaHighlandStory: cuncaichaHighlandPreview,
  deerStoneStory: deerStonePreview,
  dongSonStory: dongSonPreview,
  hittiteStory: hittitePreview
}

const stories = {
  ainGhazalStory,
  assyrianStory,
  bellBeakerEnglandEBAStory,
  bellBeakerScotlandEBAStory,
  bellBeakerWalesEBAStory,
  bellBeakerScotlandLBAStory,
  bellBeakerEnglandMBAStory,
  bellBeakerWalesMBAStory,
  bellBeakerSpainStory,
  canaanitesStory,
  chongoniRockArtistsStory,
  cordedWareGermanyStory,
  cuncaichaHighlandStory,
  deerStoneStory,
  dongSonStory,
  hittiteStory,
  jomonStory,
  lapitaStory,
  minoanStory,
  mycenaeanStory,
  preDorsetStory,
  protocogotasStory,
  sakaStory,
  sanNicolasIslandStory,
  saqqaqStory,
  southWesternIberianBronzeStory,
  yamnayaStory
}


export default stories