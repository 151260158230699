import React, { useContext } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import AppContext from './app-context'
import isEmpty from 'lodash.isempty'

function CopyrightsPage () {
  const { geneticTestConfig, isPreview } = useContext(AppContext)

  if (isEmpty(geneticTestConfig)) return null

  let {
    description,
    genepoolIconsRefs = '',
    cultureIconRefs = ''
  } = (geneticTestConfig || { cprp: {} }).cprp

  genepoolIconsRefs = genepoolIconsRefs.split(',').map(i => i.trim())
  cultureIconRefs = cultureIconRefs.split(',').map(i => i.trim())

  return (
    <div className='c-page faq-wrap faq'>
      <Navbar isApp={!isPreview} />
      <div className='page-header-wrap'>
        <div className='content'>
          <div className='page-title'>
            <h1 className='t-black'>Icons copyrights<span /></h1>
          </div>
        </div>
      </div>
      <div className='grid faq-section section-text'>
        <div className='col-md-offset-3 col-md-6 section-text'>
          <p>{description}</p>
        </div>
        <div className='col-md-offset-3 col-md-6 section-text'>
          <h4>Gene Pool Icons</h4>
          {genepoolIconsRefs.map((i, idx) => <a href={i} className='icofont-before icofont-ui-press' target='_blank' rel='noopener noreferrer' key={'gp-icon-' + idx}>{i}</a>)}
        </div>
        <div className='col-md-offset-3 col-md-6 section-text'>
          <h4>Cultural icons</h4>
          {cultureIconRefs.map((i, idx) => <a href={i} className='icofont-before icofont-ui-press' target='_blank' rel='noopener noreferrer' key={'cult-icon-' + idx}>{i}</a>)}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CopyrightsPage
