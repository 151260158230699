// TODO: Add the following to the App component
// handle page refresh when in app mode
// use session storage
// https://stackoverflow.com/questions/5004978/check-if-page-gets-reloaded-or-refreshed-in-javascript

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

import CulturesPage from './components/cultures-page'
import CulturesPageId from './components/cultures-page-id'
import GenePoolsPage from './components/gene-pools-page'
import HomePage from './components/home-page'
import Preview from './components/preview-page'
import FaqPage from './components/faq-page'
import CopyrightsPage from './components/copyrights-page'
import { ScrollToTop, useGeneticTestQueryParameter } from './utils/hooks'
import stories, { previews } from './components/stories'

import confApp from './config/genetic-tests.csv'
import { AppContextProvider } from './components/app-context'
import {
  separateAndOrderBeginnerAndAdvancedCulturesAndGenePools,
  deepen,
  parseContent,
  getDataFromCsv,
  getDataFromTxt
} from './utils'

import './App.scss'
import './styles/_sidebar.scss'

let lastReportSet = null

const cultureRowCallback = (r) => {
  if (r.Culture) {
    r.linkName = r.Culture.replace(/,?\s,?/gi, '-').toLowerCase()
    r.preview = r.story && previews[r.story] ? previews[r.story] : null
    r.story = r.story && stories[r.story] ? stories[r.story] : r.story
  }
}

function App () {
  const [appConf, setAppConf] = useState(null)
  const [geneticTestConf, setGeneticTestConf] = useState(null)
  const [culturesConf, setCulturesConf] = useState([])
  const [genePoolsConf, setGenePoolsConf] = useState([])
  const [culturesConfFull, setCulturesConfFull] = useState([])
  const [genePoolsConfFull, setGenePoolsConfFull] = useState([])
  const [faqConf, setFaqConf] = useState([])
  const [isBeginner, setIsBeginner] = useState(false)
  const [report, _setReport] = useState()
  const [isPreview, setIsPreview] = useState(true)
  const [iframeData, setIframeData] = useState(null)
  const [previewData, setPreviewData] = useState(null)
  const [isReadyToRender2, setIsReadyToRender2] = useState(false)

  const geneticTest = useGeneticTestQueryParameter() || 'bronze_age'

  const setReport = useCallback((rep) => {
    const temp = JSON.stringify(rep)
    if (!lastReportSet || lastReportSet !== temp) {
      lastReportSet = temp
      _setReport(rep)
    }
  }, [_setReport])

  // set the title of the page from the genetic test configuration
  useEffect(() => {
    // console.log('set title')
    const { title } = (geneticTestConf || { site: {} }).site
    if (title) document.title = title
  }, [geneticTestConf])

  useEffect(() => {
    let isCurrentRenderLoop = true
    // console.log('get app configuration')
    const fetchData = async () => {
      const data = await getDataFromCsv(confApp)
      if (isCurrentRenderLoop) setAppConf(data)
    }
    fetchData()
    return () => (isCurrentRenderLoop = false)
  }, [setAppConf])

  // get the genetic test configuration from the app configuration
  if (Array.isArray(appConf) && geneticTest && geneticTestConf?.name !== geneticTest) {
    let gtc = appConf.find(ac => ac.name === geneticTest)
    if (gtc) gtc = deepen(gtc)
    // console.log('setGeneticTestConf', gtc)
    setGeneticTestConf(gtc)
  }

  // get the cultures and gene pools csv configurations from the genetic test configuration
  useEffect(() => {
    let isCurrentRenderLoop = true
    const fetchData = async () => {
      // console.log('get cultures and gene pools csv configurations')
      if (!geneticTestConf) return
      const {
        genepoolFile,
        culturesFile,
        faqFile
      } = geneticTestConf
      // console.log('genepoolFile', genepoolFile)
      // console.log('culturesFile', culturesFile)
      const prom1 = getDataFromCsv(culturesFile, { rowCallback: cultureRowCallback })
      const prom2 = getDataFromCsv(genepoolFile)
      const prom3 = getDataFromCsv(faqFile)
      const [cultRows, gpRows, faqRows] = await Promise.all([prom1, prom2, prom3])
      if (isCurrentRenderLoop) {
        setCulturesConfFull(cultRows)
        setGenePoolsConfFull(gpRows)
        setFaqConf(faqRows)
      }
    }
    fetchData()
    return () => (isCurrentRenderLoop = false)
  }, [geneticTestConf, setCulturesConfFull, setFaqConf, setGenePoolsConfFull])

  // separate beginner and advanced cultures and gene pools
  useEffect(() => {
    // console.log('separate beginner and advanced cultures and gene pools')
    if (culturesConfFull?.length > 0 && genePoolsConfFull?.length > 0) {
      // console.log('useEffect', isBeginner, culturesConfFull, genePoolsConfFull)
      const {
        beginnerGenePools,
        advancedGenepools,
        beginnerCultures,
        advancedCultures
      } = separateAndOrderBeginnerAndAdvancedCulturesAndGenePools(culturesConfFull, genePoolsConfFull)
      if (isBeginner) {
        // console.log('setCulturesConf(beginnerCultures)', beginnerCultures)
        // console.log('setGenePoolsConf(beginnerGenePools)', beginnerGenePools)
        setCulturesConf(beginnerCultures)
        setGenePoolsConf(beginnerGenePools)
      } else {
        // console.log('setCulturesConf(advancedCultures)', advancedCultures)
        // console.log('setGenePoolsConf(advancedGenepools)', advancedGenepools)
        setCulturesConf(advancedCultures)
        setGenePoolsConf(advancedGenepools)
      }
    }
  }, [culturesConfFull, genePoolsConfFull, isBeginner])

  // set the genetic distance for each culture
  useMemo(() => {
    if (!report || culturesConf.length === 0) return
    if (isBeginner) {
      // console.log('set genetic distance BEGINNER', JSON.parse(JSON.stringify(report)))
      report.result = { ...report.beginnerResult }
      report.geneticDistance = { ...report.beginnerGDistanceResult }
    } else {
      // console.log('set genetic distance ADVANCED', JSON.parse(JSON.stringify(report)))
      report.result = { ...report.advancedResult }
      report.geneticDistance = { ...report.advancedGDistanceResult }
    }
    const distances = culturesConf.map(c => report.geneticDistance[c.Culture])
    // console.log('distances', distances)
    distances.sort((a, b) => b - a)
    for (const cult in report.geneticDistance) {
      const value = report.geneticDistance[cult]
      report.geneticDistance[cult] = distances.indexOf(value)
    }
    culturesConf.forEach(c => {
      if (report.geneticDistance[c.Culture] == null) console.log(c.Culture, 'has no genetic distance')
      c.geneticDist = report.geneticDistance[c.Culture]
    })
  }, [report, culturesConf, isBeginner])

  // check if the preview is for beginner or advanced
  if (window.location && window.location.search && isPreview) {
    if (/app=beginner/gi.test(window.location.search)) {
      // console.log('useEffect setIsBeginner(true)')
      if (!isBeginner) setIsBeginner(true)
    } else if (/app=advanced/gi.test(window.location.search)) {
      // console.log('useEffect setIsBeginner(false)')
      if (isBeginner) setIsBeginner(false)
    }
  }

  // set the report from the preview data
  useEffect(() => {
    // skip if not preview
    if (!isPreview || geneticTestConf?.previewData == null) return

    let isCurrentRenderLoop = true
    let previewData = geneticTestConf.previewData
    if (isBeginner && geneticTestConf.previewDataBgn?.length > 0) {
      previewData = geneticTestConf.previewDataBgn
    }
    const fetchPreviewData = async () => {
      let data = null
      // is it a txt file?
      if (previewData.match(/\.txt$/gi)) {
        data = await getDataFromTxt(previewData)
      } else {
        data = previewData
      }
      if (isCurrentRenderLoop) {
        setPreviewData(data)
      }
    }
    fetchPreviewData()
    return () => (isCurrentRenderLoop = false)
  }, [geneticTestConf, isBeginner, isPreview, setPreviewData])

  // listen for messages from the iframe
  useEffect(() => {
    const messageListener = (event) => {
      if (event?.data && typeof event.data === 'string') {
        try {
          // console.log('event.data', event)
          let parsed = null
          try {
            parsed = JSON.parse(event.data)
          } catch (error) {
            // console.error(error)
            // console.error(event.data)
            // silently ignore
            return
          }
          const { reportPage, preview } = parsed || {}
          // we JSON parsed another message data
          if (reportPage == null && preview == null) return
          if (preview) {
            // console.log('isPreview(true)')
            if (!isPreview) setIsPreview(true)
          } else {
            // console.log('isPreview(false)')
            setIsPreview(false)
            setIframeData(reportPage)
          }
          // console.log('setreport messageListener')
        } catch (e) {
          console.error(e)
        }
      }
    }
    window.addEventListener('message', messageListener)
    return () => window.removeEventListener('message', messageListener)
  }, [setIsPreview, setIframeData, isPreview])

  useEffect(() => {
    if (culturesConfFull?.length > 0 && genePoolsConfFull?.length > 0) {
      let rep = null
      if (isPreview && previewData) {
        // console.log('parseContent(previewData)', parseContent(previewData))
        rep = parseContent(previewData, culturesConfFull, genePoolsConfFull)
        console.log('setReport(previewData)')
      } else if (iframeData) {
        // console.log('parseContent(iframeData)', parseContent(iframeData))
        rep = parseContent(iframeData, culturesConfFull, genePoolsConfFull)
        console.log('setReport(iframeData)')
      }
      if (rep != null) {
        setReport(rep)
        if (isBeginner !== rep.isBeginner) setIsBeginner(rep.isBeginner)
      }
    }
  }, [isPreview, genePoolsConfFull, culturesConfFull, iframeData, previewData, setIsBeginner, setReport, isBeginner])

  // very strange bug, without this the preview page does not render for isPreview = true
  useEffect(() => {
    const timeoutId = setTimeout(() => setIsReadyToRender2(true), 500)
    return () => clearTimeout(timeoutId)
  }, [setIsReadyToRender2])

  // console.log('report.advancedGDistanceResult', Object.keys(report?.advancedGDistanceResult ?? {}).length)
  // console.log('culturesConf.length', culturesConf.length)

  const isReadyToRender = culturesConf?.length > 0 && genePoolsConf?.length > 0 && report?.geneticDistance != null && report?.result != null

  // console.log('report', report)
  // console.log('isPreview', isPreview)
  // console.log('isBeginner', isBeginner)
  // console.log('culturesConf', culturesConf)
  // console.log(
  //   'isReadyToRender', isReadyToRender,
  //   'culturesConf?.length > 0', culturesConf?.length > 0,
  //   'genePoolsConf?.length > 0', genePoolsConf?.length > 0,
  //   'report?.geneticDistance != null', report?.geneticDistance != null,
  //   'report?.result != null', report?.result != null,
  //   'isPreview', isPreview,
  //   'isBeginner', isBeginner,
  //   'report', report
  // )

  return (
    <AppContextProvider geneticTestConfig={geneticTestConf} isPreview={isPreview}>
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      <ScrollToTop />
      <Switch>
        <Route path='/404'>
          <h1>404</h1>
          <div className='row'>
            <div className='content col-md-9'>
              <h1>Not Found</h1>
            </div>
          </div>
        </Route>
        <Route path={['/app', '/preview']}>
          <Preview culturesConf={isReadyToRender && isReadyToRender2 ? culturesConf : []} genePoolsConf={genePoolsConf} isBeginner={isBeginner} report={isReadyToRender && isReadyToRender2 ? report : null} setIsBeginner={setIsBeginner} isPreview={isPreview} />
        </Route>
        <Route path='/faq'>
          <FaqPage faqs={faqConf} />
        </Route>
        <Route path='/gene-pools'>
          <GenePoolsPage genePools={genePoolsConf} />
        </Route>
        <Route
          path='/cultures/:culture'
          render={(routeProps) => <CulturesPageId cultures={culturesConf} cultureName={routeProps.match.params.culture} isPreview={isPreview} />}
        />
        <Route path='/cultures'>
          <CulturesPage cultures={culturesConf} />
        </Route>
        <Route path='/icons-copyrights'>
          <CopyrightsPage />
        </Route>
        <Route path='/'>
          <HomePage culturesConf={culturesConfFull} genePoolsConf={genePoolsConfFull} />
        </Route>
      </Switch>
    </AppContextProvider>
  )
}

export default App
