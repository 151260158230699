import React from 'react'
import { Years } from '../years'
import image01 from '../../assets/images/cultures/Bell_beaker_mba_england_text_01.jpg'
import image02 from '../../assets/images/cultures/Bell_beaker_mba_england_text_02.jpg'

export const bellBeakerEnglandMBAPreview = 'The English Bronze Age lasted from roughly 2500-700 BC, with the period between 1500-1000 BC considered the Middle Bronze Age. This period is characterized by the formal end of the Beaker culture and the rise of the Atlantic Bronze Age, characterized by the high economic and cultural maritime exchange of goods and ideas in Western Europe and Britain. More items were now deposited in water bodies like lakes, rivers, and bogs. During this period, the Celts began to form in Europe as a new distinct Indo-European culture.'

function bellBeakerMBAEngland (props) {
  return (
    <>
      <h2>Post Bell Beaker culture at the Middle Bronze Age in England (<Years culture={props} />)</h2>
      <br />
      {bellBeakerEnglandMBAPreview}
      <br />
      <br />
      Developments in warfare during this time included the formation of the Yetholm-type shield (“The Camelot Shield”), a round shield made of a thick copper alloy (11-14% tin) sheet used between 1200 and 800 BC. The handle was made from a thicker piece of sheet bronze.
      <br />
      <br />
      <img src={image01} alt='The post Bell Beaker culture at the Middle Bronze Age in England' />
      <legend> A Bronze Yetholm-type shield from the Early Bronze Age period found in England (Source: Wikimedia commons)</legend>
      <br />
      These new shields were substantially harder than the copper shields (and certainly wooden ones) previously used. Such shields were discovered near the Cadbury Castle hillfort (Somerset), which is associated with King Arthur’s Camelot
      <br />
      <br />
      <img src={image02} alt='The post Bell Beaker culture at the Middle Bronze Age in England' />
      <legend> A Yetholm type shield found at South Cadbury (Source: Wikimedia commons)</legend>
      <br />
      Some of the individuals included in this test were found in Bell Beaker cemeteries, largely a post-Beaker innovation, which underlay Collared Urn-associated Bronze Age round barrow, whereas others were found in graves that could not be associated with a particular culture. Individuals included in this test were accompanied by copper alloy knife-daggers, pierced bone points, and jet-like bracelet. One of the young adult females was found to have dental disease and lesions characteristic of cribra orbitalia and periostitis, caused by infection. This finding indicates the inaccessibility of medical knowledge of the inhabitants. Another female of 20-30 years of age had an iron deficiency anemia, periostitis, and sinusitis likely due to health problems encountered during childhood and adulthood.  Lesions observed in her spine indicates exposure to heavy loads from a young age. Some post-mortem manipulations, such as cut marks on the neck of some of the adults, were also observed and are likely related to cultic activity. In the Biddenham Loop site, a cow was buried close to a segmented ditch where several males were buried. This was one of the earliest practices of joined human-cow burials, which lasted 500 years.
      <br />
      <br />
      The genetic evidence shows that following their arrival to Britain, the Bell Beakers have experienced a reduction in their Yamnaya and Western European ancestries. This is not surprising since they no longer were in contact with these populations but with the local Neolithic populations. Indeed, the proportion of the UK ancestry has increased in these populations at the expense of their former ancestries
      <br />
      <br />
      All the 9 males of the Beaker culture carried the Y-chromosome haplogroup R1b1a1a2a commonly found in Europe, Anatolia, the Caucasus, and its subbranches. The mitochondrial haplogroups of the nine males and 12 females included a wide variety of H, J, K, T, U, and W, which supports a continuous mix with females alongside the decline of some haplogroups. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup K is common in Europe today and was recently derived from U, which was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup T is probably Middle Eastern in origin and found today throughout Central Asia and in low frequencies in Europe. Haplogroup W has originated around 23,900 years ago in West Asia and can still be found there as well as Europe.
      <br />
      <br />
      If you want to read more, we recommend reading <strong><a target='_blank' rel='noopener noreferrer' href='https://amzn.to/340saTE'>"History of England: A Captivating Guide to English History, Starting from Antiquity through the Rule of the Anglo-Saxons, Vikings, Normans, and Tudors to the End of World War 2"</a></strong>
      <br />
      <br />
      <iframe title='corded ware germany book' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=164748278X&asins=164748278X&linkId=b372814e8c706fed715e20d065442797&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
    </>
  )
}
export default bellBeakerMBAEngland
